/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';
import { find } from 'min-dash';

export const isSupportedElement = (element) => {
  const businessObject = getBusinessObject(element);

  if (!businessObject) {
    return false;
  }

  return (
    businessObject.hasOwnProperty('modelerTemplate') || businessObject.$attrs?.hasOwnProperty('zeebe:modelerTemplate')
  );
};

export const sanitizeTemplateName = (name) => {
  return name.replace('- Copy', '').trim();
};

/**
 * Returns a connector template from the given element.
 * @returns {object} Connector template from the given element
 */
export const getConnectorTemplateFromElement = ({ modeler, element }) => {
  if (!isSupportedElement(element) || !modeler) {
    return null;
  }

  const elementTemplates = modeler.get('elementTemplates');
  const connectorTemplate = { ...elementTemplates.get(element) };
  const isEmptyTemplate = Object.keys(connectorTemplate).length === 0;

  if (isEmptyTemplate) {
    return null;
  }

  return connectorTemplate;
};

/**
 * Hydrates the connector template with values from the given element.
 */
export const hydrateTemplateWithProperties = ({ template, element }) => {
  if (!template) {
    return;
  }

  const { properties: connectorProperties } = template;
  const businessObject = getBusinessObject(element);

  if (!businessObject.extensionElements || !connectorProperties) {
    return;
  }

  const ioMappingValues = find(
    businessObject.extensionElements.get('values'),
    (value) => value.$type === 'zeebe:IoMapping'
  );
  const taskHeadersValues = find(
    businessObject.extensionElements.get('values'),
    (value) => value.$type === 'zeebe:TaskHeaders'
  );

  const inputs = ioMappingValues?.get('inputParameters') || [];
  const headers = taskHeadersValues?.get('values') || [];

  connectorProperties.forEach((property) => {
    if (property.type === 'Hidden') {
      return;
    }

    const { name, key, type } = property.binding;
    let value;

    if (type === 'zeebe:input') {
      const input = find(inputs, (input) => input.target === name);
      value = input?.source;
    } else if (type === 'zeebe:taskHeader') {
      const header = find(headers, (header) => header.key === key);
      value = header?.value;
    }

    if (value) {
      property.value = value;
    }
  });
};
