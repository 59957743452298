/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { clustersStore } from 'stores';
import getClusterStatus from 'utils/cluster/get-cluster-status';
import { CLUSTER_STATUS } from 'utils/cluster/constants';

const useClusterStatus = (selectedClusterId) => {
  const { clusters } = clustersStore;
  const selectedCluster = clusters?.find((cluster) => cluster.uuid === selectedClusterId);
  return selectedCluster ? getClusterStatus(selectedCluster.status) : CLUSTER_STATUS.MISSING;
};

export default useClusterStatus;
