/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import detectConnectorsStore from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/detectConnectors/DetectConnectorsStore';
import detectUserTasksStore from 'App/Pages/Diagram/BpmnJSExtensions/detectUserTasksExtension/DetectUserTasksStore';
import { getProcessIdFromContent } from 'utils/web-modeler-diagram-parser';
import { BPMN, BULK, CONNECTOR_TEMPLATE, DEFAULT, FILE_TYPE_MAPPING } from 'utils/constants';
import { getFlowNodes, getFormFields, getFormFieldTypeCounts } from 'utils/helpers';
import config from 'utils/config';
import { PUBLISHED_ON } from 'utils/milestones/published-on';

import MixpanelIntegration from './MixpanelIntegration';

export class TrackingService extends MixpanelIntegration {
  #getConnectorsFromXml(xml) {
    return detectConnectorsStore.detectConnectors(xml);
  }

  #getConnectorsFromModeler() {
    const connectors = detectConnectorsStore.connectors;
    if (connectors) {
      return Array.from(connectors);
    }
    return [];
  }

  #getContainsUserTasksFromXml(xml) {
    return detectUserTasksStore.detectContainsUserTasks(xml);
  }

  #getContainsUserTasksFromModeler() {
    return detectUserTasksStore.hasUserTasks;
  }

  /**
   * Enriches the given payload with the information about the template used.
   *
   * @param {String} content The XML content of the template diagram.
   * @param {Object} payload The mixpanel payload to be enriched.
   * @returns {Object}
   */
  async #injectTemplateUsed(content, payload) {
    if (content.length > 0) {
      const processId = await getProcessIdFromContent(content);
      payload.templateUsed = processId;
    }

    return payload;
  }

  async trackCreateFile(fileId, from, type, parentType, content = '', processTemplateUsed, parentFileId, isC7Import) {
    const payload = {
      fileId,
      createdFrom: from,
      fileType: FILE_TYPE_MAPPING[type],
      parentType: FILE_TYPE_MAPPING[parentType]
    };
    if (type === 'BPMN') {
      payload.connectors = await this.#getConnectorsFromXml(content);
      payload.containsUserTasks = await this.#getContainsUserTasksFromXml(content);

      if (processTemplateUsed) {
        payload.templateUsed = processTemplateUsed;
      }
    }
    if (parentFileId) {
      payload.parentFileId = parentFileId;
    }
    if (isC7Import) {
      payload.c7Import = isC7Import;
    }

    this.track('modeler:create:file', payload);
  }

  trackViewFile = (origin, user, file, modeler, extraProps) => {
    const props = {
      ...extraProps,
      origin: origin,
      user: user
    };

    // the diagram and user are not disclosed in share and embeds
    if (!['share', 'embed'].includes(origin)) {
      props.fileId = file.id;
      props.fileType = FILE_TYPE_MAPPING[file.type];

      switch (props.fileType) {
        case 'bpmn':
        case 'dmn':
          props.flowNodes = getFlowNodes(file, modeler)?.length;
          break;
        case 'form':
          props.formFields = getFormFields(file)?.length;
          break;
      }
    }

    this.#trackInternalViewFile(props);
  };

  #trackInternalViewFile(payload) {
    this.track('modeler:view:file', payload);
  }

  trackEditFile({ fileId, type, mode, source }) {
    if (type === 'FORM') {
      this.increment('modeler:formChanges');
    } else if (type === 'CONNECTOR_TEMPLATE') {
      this.increment('modeler:connectorChanges');
    } else {
      this.increment(`modeler:diagramChanges${type === 'DMN' ? ':dmn' : ''}`);
    }

    const props = { fileId, fileType: FILE_TYPE_MAPPING[type] };
    if (type === 'BPMN') {
      props.connectors = this.#getConnectorsFromModeler();
      props.containsUserTasks = this.#getContainsUserTasksFromModeler();
    }

    if (mode) {
      props.mode = mode;
    }

    if (source) {
      props.source = source;
    }

    this.track('modeler:edit:file', props);
  }

  trackShareFile(fileId, type) {
    this.track('modeler:share:file', {
      fileId,
      fileType: FILE_TYPE_MAPPING[type]
    });
  }

  trackCloseFile(fileId, type, content) {
    const fileType = FILE_TYPE_MAPPING[type];

    const props = {
      fileId,
      fileType
    };

    if (type === 'FORM') {
      const formFields = getFormFields({ type, content });
      props.formFieldTypes = getFormFieldTypeCounts(formFields);
    }

    this.track('modeler:close:file', props);
  }

  async trackViewTemplate(content) {
    this.track('modeler:template:details', await this.#injectTemplateUsed(content, {}, true));
  }

  trackProjectInvite(rights, numberOfInvites) {
    this.track('modeler:invite:project', {
      rights,
      numberOfInvites
    });
  }

  trackSingleProjectInvitation({ email, projectAccess, isExternal }) {
    this.track('modeler:invite:project:v2', {
      email,
      projectAccess,
      isExternal
    });
  }

  trackCreateProject(from) {
    this.track('modeler:create:project', {
      createdFrom: from === 'move' ? 'move-entity' : from === 'copy' ? 'copy-from-milestone' : from
    });
  }

  trackCreateFolder({ from, parentType, folderType, defaultDevClusterId }) {
    this.track('modeler:create:folder', {
      createdFrom: from === 'move' ? 'move-entity' : from === 'copy' ? 'copy-from-milestone' : from,
      parentType: parentType.toLowerCase(),
      folderType,
      defaultDevClusterId
    });
  }

  trackRename(type = DEFAULT) {
    this.track(`modeler:rename:${FILE_TYPE_MAPPING[type]}`);
  }

  trackDeleteEntities(type, numberOfFiles = 0, numberOfFolders = 0, numberOfProcessApplications = 0) {
    this.track('modeler:delete:entity', {
      parentType: FILE_TYPE_MAPPING[type],
      numberOfFiles,
      numberOfFolders,
      numberOfProcessApplications
    });
  }

  /**
   * @typedef {'BPMN' | 'DMN' | 'PROJECT' | 'FOLDER' | 'FORM' | 'CONNECTOR_TEMPLATE' | 'BULK'} FileTypeKey
   */
  /**
   * @typedef {'json' | 'png' | 'svg' | 'xml' | 'zip'} ExportType
   */
  /**
   * @typedef {'form' | 'diagram' | 'connector-template' | 'project' | 'folder'} FromPage
   */

  /**
   * @param {string | 'BULK'} fileId - The ID of the file or 'BULK' for bulk export.
   * @param {FileTypeKey} fileTypeKey - The file type key to be used to retrieve the related file type, 'BULK' otherwise.
   * @param {ExportType} exportType - The type of export.
   * @param {FromPage} from - The source of the export.
   * @param {number} fileCount - The number of files exported.
   * @param {number} folderCount - The number of folder exported.
   * @param {number} fileSize - The total size of the exported files.
   */
  trackFileExport({ fileId, fileTypeKey, exportType, from, fileCount = 0, folderCount = 0, fileSize }) {
    this.track('modeler:export:file', {
      fileId,
      fileType: FILE_TYPE_MAPPING.hasOwnProperty(fileTypeKey) ? FILE_TYPE_MAPPING[fileTypeKey] : BULK,
      exportType,
      from,
      fileCount,
      folderCount,
      fileSize
    });
  }

  async trackCreateMilestone(from, file, organizationPublic) {
    const props = {
      createdFrom: from,
      fileType: FILE_TYPE_MAPPING[file.type],
      fileId: file.id
    };
    if (file.type === BPMN) {
      props.connectors = await this.#getConnectorsFromXml(file.content);
      props.containsUserTasks = await this.#getContainsUserTasksFromXml(file.content);
    }
    if (file.type === CONNECTOR_TEMPLATE) {
      props.publicationTarget = organizationPublic ? PUBLISHED_ON.ORGANIZATION : PUBLISHED_ON.PROJECT;
    }
    this.track('modeler:create:milestone', props);
  }

  trackPublicationMilestoneToOrganization({ from, fileId, fileType }) {
    const props = {
      createdFrom: from,
      fileId,
      fileType,
      publicationTarget: PUBLISHED_ON.ORGANIZATION
    };

    this.track('modeler:update:milestone', props);
  }

  trackPageView(name) {
    this.track('modeler:view:page', {
      view: name,
      url: location.href
    });
  }

  trackAddComment({ file, elementType, mentions, previousCommenters, previousComments }) {
    this.increment('modeler:addedComments');
    this.track('modeler:add:comment', {
      fileId: file.id,
      fileType: FILE_TYPE_MAPPING[file.type],
      elementType,
      mentions,
      previousCommenters,
      previousComments
    });
  }

  trackLicenseCheck(license, expired) {
    this.track('modeler:licenseCheck', { license, expired });
  }

  /**
   * Tracks the new cluster creation journey.
   * @param diagram The diagram for which the cluster is created.
   */
  // See https://github.com/camunda/web-modeler/issues/8421
  trackClusterCreation({ diagram }) {
    const props = {
      fileId: diagram.id,
      fileType: FILE_TYPE_MAPPING[diagram.type],
      from: 'modeler'
    };

    this.track('createCluster:open', props);
  }

  /**
   * @typedef {Object} DeploymentParams
   * @property {Object} diagram - The diagram to be deployed.
   * @property {('single-file'|'process-application')} deployType - The type of deployment.
   * @property {boolean} success - Whether the deployment was successful.
   * @property {string} errorCode - The error code if the deployment failed.
   * @property {string} errorMessage - The error message if the deployment failed.
   * @property {Array} deployedForms - The forms deployed with the diagram.
   * @property {string} clusterVersion - The version of the cluster.
   * @property {string} clusterEnv - The environment of the cluster.
   * @property {number} numberOfFiles - The number of files deployed.
   * @property {number} bundleSize - The size of the bundle.
   */

  /**
   * Tracks the deployment of a resource.
   * @param {DeploymentParams} params - The deployment parameters.
   */
  trackDeployment({
    diagram,
    deployType,
    success,
    errorCode,
    errorMessage,
    deployedForms,
    clusterVersion,
    clusterEnv,
    numberOfFiles,
    bundleSize
  }) {
    this.#trackDeployRun({
      action: 'deploy',
      deployType,
      diagram,
      success,
      errorCode,
      errorMessage,
      deployedForms,
      clusterVersion,
      clusterEnv,
      numberOfFiles,
      bundleSize
    });
  }

  /**
   * Tracks the run instance of a resource.
   * @param {DeploymentParams} params - The deployment parameters.
   */
  trackExecution({
    diagram,
    deployType,
    success,
    errorCode,
    errorMessage,
    deployedForms,
    clusterVersion,
    clusterEnv,
    numberOfFiles,
    bundleSize
  }) {
    this.#trackDeployRun({
      action: 'run',
      deployType,
      diagram,
      success,
      errorCode,
      errorMessage,
      deployedForms,
      clusterVersion,
      clusterEnv,
      numberOfFiles,
      bundleSize
    });
  }

  /**
   * Tracks the deployment or run of a resource.
   * @param {('run'|'deploy')} action The action that was performed.
   * @param {DeploymentParams} params - The deployment parameters.
   */
  #trackDeployRun({
    action,
    diagram,
    deployType,
    success,
    errorCode,
    errorMessage,
    deployedForms,
    clusterVersion,
    clusterEnv,
    numberOfFiles,
    bundleSize
  }) {
    const props = {
      fileId: diagram?.id,
      fileType: FILE_TYPE_MAPPING[diagram?.type],
      deployType,
      success,
      errorCode,
      errorMessage,
      numberOfFiles,
      bundleSize,
      deployedForms,
      clusterVersion,
      clusterTag: clusterEnv
    };

    if (diagram?.type === 'BPMN') {
      props.connectors = this.#getConnectorsFromModeler();
      props.containsUserTasks = this.#getContainsUserTasksFromModeler();
    }

    const eventName = action === 'run' ? 'modeler:start:confirm' : 'modeler:deploy:confirm';
    this.track(eventName, props);
  }

  trackForceRelogin() {
    this.track('modeler:login:force', {
      url: location.href
    });
  }

  trackTokenSimulation(eventType) {
    this.track('modeler:tokenSimulation:click', {
      eventType
    });
  }

  trackTargetEngineVersion(file, value) {
    this.track('modeler:executionPlatform:version', {
      value,
      fileId: file.id,
      fileType: file.type
    });
  }

  trackDiagramErrors(file, type, count) {
    this.track('modeler:diagramErrors:count', {
      type,
      count,
      fileId: file?.id,
      fileType: file?.type
    });
  }

  trackAssetRefresh() {
    this.track('modeler:refresh');
  }

  /**
   * action: openMenu|link|unlink
   * resource: form|decision|BPMN
   * from: BPMN|form
   */
  trackResourceLinking({ action, resource, from }) {
    this.track('modeler:resource:link', {
      action,
      resource,
      from
    });
  }

  trackPopover(type) {
    this.track('modeler:popover', { type });
  }

  trackTopBarAction(item, section, props) {
    if (config.features.trackTopbarEnabled) {
      this.track('modeler:topbarClicked', { ...props, section, item });
    }
  }

  trackFormEditorLayoutChanged(layout, triggeredBy, fileId) {
    this.track('modeler:formEditor:layoutChanged', {
      layout,
      triggeredBy,
      fileId
    });
  }

  trackFormEditorPreviewChanged(fileId) {
    this.track('modeler:formEditor:previewChanged', { fileId });
  }

  trackFormEditorInputDataChanged(fileId) {
    this.track('modeler:formEditor:inputDataChanged', { fileId });
  }

  trackFormAssistantOpen(origin) {
    this.track('modeler:formAssistant:open', { origin });
  }

  trackFormAssistantClose(page) {
    this.track('modeler:formAssistant:close', { page });
  }

  trackFormAssistantGenerationStart(prompt) {
    this.track('modeler:formAssistant:generationStart', { prompt });
  }

  trackFormAssistantGenerationError(errorMessage) {
    this.track('modeler:formAssistant:generationError', { errorMessage });
  }

  trackFormAssistantGenerationSuccess() {
    this.track('modeler:formAssistant:generationSuccess');
  }

  trackFormAssistantImportError(errorMessage) {
    this.track('modeler:formAssistant:importError', { errorMessage });
  }

  trackFormAssistantImportSuccess() {
    this.track('modeler:formAssistant:importSuccess');
  }

  trackDiagramModeChange(fileId, type, mode, projectAccess) {
    this.track('modeler:diagram:changeMode', {
      fileId,
      fileType: FILE_TYPE_MAPPING[type],
      mode,
      projectAccess
    });
  }

  trackActionsMenuOpening(fileId, type, mode) {
    this.track('modeler:file:activityMenuOpen', {
      fileId,
      fileType: FILE_TYPE_MAPPING[type],
      mode
    });
  }

  trackActionsMenuItemClick(item, fileId, type, mode) {
    this.track('modeler:file:activityMenuItemClick', {
      item,
      fileId,
      fileType: FILE_TYPE_MAPPING[type],
      mode
    });
  }

  trackDetailsPanelTabClick(target) {
    this.track('modeler:detailsPanelTab:open', { target });
  }

  trackInboundConnectorTabClick() {
    this.trackDetailsPanelTabClick('inbound');
  }

  trackInboundConnectorURLCopy(processId, id) {
    this.track('modeler:webhookURL:copy', { processId, webhookId: id });
  }

  trackPublicLinkCopy(processId) {
    this.track('modeler:publicLink:copy', { processId });
  }

  trackCheckoutPage(displayedSalesPlan) {
    this.track('checkout:open', { from: 'Modeler', displayedSalesPlan });
  }

  trackPublicationTabClick() {
    this.trackDetailsPanelTabClick('publication');
  }

  trackPublicationGroupExpand() {
    this.track('modeler:publicationGroup:expand');
  }

  trackPublicationToggleClick(processId, flag) {
    this.track(`modeler:publication:${flag ? 'enable' : 'disable'}`, {
      processId
    });
  }

  trackDiagramAction({ eventName, fileId, type, mode, eventData }) {
    const props = {
      fileId,
      fileType: FILE_TYPE_MAPPING[type],
      mode,
      ...eventData
    };
    this.track(`modeler:${eventName}`, props);
  }

  trackSaveAsConnectorTemplate({ connectorId, fileId, type }) {
    const props = { fileId, fileType: FILE_TYPE_MAPPING[type], connectorId };
    this.track('modeler:connector:saveAs', props);
  }

  trackDuplicateImportedConnectorTemplate({ connectorId, fileId, type }) {
    const props = { fileId, fileType: FILE_TYPE_MAPPING[type], connectorId };
    this.track('modeler:connector:imported-duplicate', props);
  }

  trackResourcesImportOpening({ urls }) {
    this.track('modeler:resourceImport:open', { urls });
  }

  trackImportedResourcePublish({ urls, projectId }) {
    this.track('modeler:resourceImport:publish', {
      urls,
      projectId
    });
  }

  trackXMLEditorOpen(fileId, type) {
    this.track('modeler:xmlEditor:open', {
      fileId,
      fileType: FILE_TYPE_MAPPING[type]
    });
  }

  trackExperiment({ experimentKey, group }) {
    this.track(`modeler:experiment:${experimentKey}`, {
      group
    });
  }

  trackDocumentationUpdate(fileId) {
    this.track('modeler:documentation:update', { fileId, mode: 'Design' });
  }

  trackDocsAIOpen({ enabled, from }) {
    this.track('docsAI:open', { enabled, from });
  }

  trackBlueprintsOpening({ from }) {
    this.track('modeler:blueprints:open', { from });
  }

  trackBlueprintDownload({ id }) {
    this.track('modeler:blueprints:download', { blueprintId: id });
  }

  trackBlueprintError({ error }) {
    this.track('modeler:blueprints:error', { error });
  }

  trackTemplateCreation({ type, isIconChanged }) {
    this.track('modeler:template:create', { type, isIconChanged });
  }

  trackTemplateCreationError({ error }) {
    this.track('modeler:template:error', { error });
  }

  /**
   * @typedef {Object} GitOperationParameters
   * @property {string} provider - The provider configured for the git connection.
   * @property {('show'|'cancel'|'push'|'pull')} operation - The operation to track.
   * @property {string} processApplicationId - The ID of the process application.
   * @property {('start'|'complete'|'skip')} step - The step of the operation.
   * @property {('success'|'fail')} status - The status of the operation.
   * @property {('ours'|'theirs')} conflictResolution - The conflict resolution method.
   * @property {string} error - The error message if the operation failed.
   */

  /**
   * @typedef {Object} GitSetupParameters
   * @property {string} processApplicationId - The ID of the process application.
   * @property {string} provider - The provider configured for the git connection.
   */

  /**
   * Tracks the start of a git pull operation.
   * @param {GitOperationParameters} params - The parameters to track the operation.
   */
  trackGitOperation({ provider, operation, processApplicationId, step, status, conflictResolution, error }) {
    this.track(`modeler:git:sync`, {
      provider,
      operation,
      processApplicationId,
      step,
      status,
      conflictResolution,
      error
    });
  }

  /**
   * Tracks the configuration of the git connection.
   * @param {GitSetupParameters} params - The parameters to track the setup.
   */
  trackGitSetup({ processApplicationId, provider }) {
    this.track('modeler:git:setup', {
      processApplicationId,
      provider
    });
  }

  trackFeedbackSend({ from, score }) {
    this.track('modeler:feedback:send', {
      from,
      score
    });
  }
}

export default new TrackingService();
