/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Button, IconButton } from '@carbon/react';
import { Settings, PullRequest } from '@carbon/icons-react';

import { projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import gitSettingsStore from 'features/git-sync/settings/GitSettingsStore';
import gitSyncStore from 'features/git-sync/sync/GitSyncStore';

import * as Styled from './GitSyncButtons.styled';

const GitSyncButtons = () => {
  const { project } = projectStore;
  const {
    hasUnsavedSettings,
    hasAllRequiredSettings,
    shouldShowDialog: shouldShowSettingsDialog,
    providerLabel,
    providerIcon
  } = gitSettingsStore;

  const canSync = hasAccess(project, actions.MODIFY_DIAGRAM) && hasAllRequiredSettings;
  const canOpenSettings = hasAccess(project, actions.MODIFY_GIT_SYNC_SETTINGS);
  const hasSettings =
    (hasUnsavedSettings && shouldShowSettingsDialog) || (!hasUnsavedSettings && hasAllRequiredSettings);

  const onSync = () => {
    gitSyncStore.open();
  };

  const onSetup = () => {
    gitSettingsStore.open();
  };

  const ConnectButton = () => (
    <Button
      kind="primary"
      size="sm"
      iconDescription="Open the dialog to connect with your repository"
      renderIcon={PullRequest}
      onClick={() => onSetup()}
    >
      Connect repository
    </Button>
  );

  const SyncButton = () => (
    <Button
      kind="primary"
      size="sm"
      iconDescription={`Open the dialog to sync with ${providerLabel}`}
      renderIcon={providerIcon}
      onClick={() => onSync()}
    >
      Sync with {providerLabel}
    </Button>
  );

  const SettingsButton = () => (
    <IconButton label={`Open ${providerLabel} settings`} size="sm" kind="ghost" onClick={() => onSetup()}>
      <Settings />
    </IconButton>
  );

  return hasSettings ? (
    <Styled.Buttons>
      {canSync ? (
        <>
          <SyncButton /> {canOpenSettings && <SettingsButton />}
        </>
      ) : (
        <>{canOpenSettings && <ConnectButton />}</>
      )}
    </Styled.Buttons>
  ) : (
    <>{canOpenSettings && <ConnectButton />}</>
  );
};

export default observer(GitSyncButtons);
