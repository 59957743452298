/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import gitSettingsStore from 'features/git-sync/settings/GitSettingsStore';

import gitSyncStore from './GitSyncStore';
import * as Styled from './GitSyncDialog.styled';

const InlineNotification = () => {
  const { hasFinished } = gitSyncStore;
  const { providerLabel } = gitSettingsStore;

  let subtitle;
  let actionButtonLabel;
  let onActionButtonClick;
  let kind;

  if (hasFinished) {
    subtitle = `Your process application data has been successfully synchronized with your ${providerLabel} repository.`;
    actionButtonLabel = undefined;
    onActionButtonClick = undefined;
    kind = 'success';
  } else {
    subtitle = `Syncing will add a new commit to your ${providerLabel} repository with the process application data.`;
    actionButtonLabel = 'Learn more';
    onActionButtonClick = (event) => {
      event.target.blur();
      window.open('https://docs.camunda.io/docs/next/components/modeler/web-modeler/git-sync/');
    };
    kind = 'warning';
  }

  return (
    <Styled.Notification
      title={`${providerLabel} sync`}
      subtitle={subtitle}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={onActionButtonClick}
      statusIconDescription="notification"
      kind={kind}
      inline
      lowContrast
      hideCloseButton
    />
  );
};

export default observer(InlineNotification);
