/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { DefinitionTooltip, Link, TextInput } from '@carbon/react';
import { Launch } from '@carbon/icons-react';
import { observer } from 'mobx-react';

import gitSettingsStore from 'features/git-sync/settings/GitSettingsStore';
import { isValidUrl } from 'features/git-sync/settings/fields/validations';
import { projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';

import * as Styled from './GitFields.styled';

export const FIELDS_VALIDATION = {
  projectAccessToken: { required: true },
  projectId: { required: true },
  repositoryUrl: { required: true, customValidation: isValidUrl }
};

export const FIELDS_THAT_REQUIRE_RESYNC = ['projectId'];

const GitLabFields = ({ onChangeSetting, onInputBlur, errors }) => {
  const project = projectStore.project;
  const canModifySettings = hasAccess(project, actions.MODIFY_GIT_SYNC_SETTINGS);
  const { fullRepositoryPath, isFullRepositoryPathValid } = gitSettingsStore;
  const canReplaceProjectAccessToken =
    canModifySettings &&
    gitSettingsStore.getCurrentSetting('projectAccessToken') &&
    gitSettingsStore.isSettingSaved('projectAccessToken');

  const handleChange = (event) => {
    const { id, value } = event.target;
    onChangeSetting(id, value);
  };

  const handleBlur = (event) => {
    const { id, value } = event.target;
    onInputBlur(id, value);
  };

  const handleReplaceProjectAccessToken = () => {
    onChangeSetting('projectAccessToken', false);
  };

  return (
    <>
      <Styled.FieldsGroupTitle>Provider details</Styled.FieldsGroupTitle>

      {canReplaceProjectAccessToken ? (
        <Styled.InlineButtons>
          <TextInput
            id="projectAccessToken"
            labelText="Access token"
            placeholder="Enter access token"
            type="password"
            value="AcmeKey"
            disabled
          />
          <Styled.InlineDeleteButton size="sm" kind="tertiary" onClick={handleReplaceProjectAccessToken}>
            Replace access token
          </Styled.InlineDeleteButton>
        </Styled.InlineButtons>
      ) : (
        <TextInput
          id="projectAccessToken"
          labelText="Access token"
          placeholder="Enter access token"
          value={gitSettingsStore.getCurrentSetting('projectAccessToken')}
          invalid={Boolean(errors['projectAccessToken'])}
          invalidText={errors['projectAccessToken']}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}

      <TextInput
        id="projectId"
        labelText="Project ID"
        placeholder="Enter project ID"
        value={gitSettingsStore.getCurrentSetting('projectId')}
        invalid={Boolean(errors['projectId'])}
        invalidText={errors['projectId']}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Styled.FieldsGroupTitle>Repository details</Styled.FieldsGroupTitle>

      <TextInput
        id="repositoryUrl"
        labelText="GitLab repository URL"
        placeholder="https://gitlab.com/"
        value={gitSettingsStore.getCurrentSetting('repositoryUrl')}
        invalid={Boolean(errors['repositoryUrl'])}
        invalidText={errors['repositoryUrl']}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Styled.TextInput
        id="branchName"
        labelText={
          <DefinitionTooltip
            definition={
              <span role="tooltip">
                Specify the target branch to sync with your GitLab repository.
                <br />
                <br />
                Duplicate the process application in a different branch to allow multiple developers to work within
                feature branches on the same process.
                <br />
                <br />
                <Styled.TooltipLink
                  href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/git-sync/"
                  target="_blank"
                >
                  Learn more about Git sync
                </Styled.TooltipLink>
              </span>
            }
            openOnHover
            align="right"
          >
            Branch
          </DefinitionTooltip>
        }
        placeholder="Enter branch name"
        value={gitSettingsStore.getCurrentSetting('branchName')}
        invalid={Boolean(errors['branchName'])}
        invalidText={errors['branchName']}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Styled.TextInput
        id="path"
        labelText={
          <DefinitionTooltip
            definition={
              <span role="tooltip">
                Web Modeler only syncs files within the defined repository path.
                <br />
                <br />
                Change the path to organize multiple process applications within the same repository.
                <br />
                <br />
                <Styled.TooltipLink
                  href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/git-sync/"
                  target="_blank"
                >
                  Learn more about Git sync
                </Styled.TooltipLink>
              </span>
            }
            openOnHover
            align="right"
          >
            Repository path (optional)
          </DefinitionTooltip>
        }
        placeholder="src/main/resources"
        value={gitSettingsStore.getCurrentSetting('path')}
        invalid={Boolean(errors['path'])}
        invalidText={errors['path']}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Link disabled={!isFullRepositoryPathValid} href={fullRepositoryPath} target="_blank">
        Open repository&nbsp;
        <Launch />
      </Link>
    </>
  );
};

export default observer(GitLabFields);
