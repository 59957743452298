/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { ChevronDown } from '@carbon/react/icons';
import { Button } from '@carbon/react';

import { AISparkle } from 'icons';

import * as Styled from './CamundaAiButtonWithCopilot.styled';

export default function CamundaAiButtonWithCopilot() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatboxOpen = () => setIsOpen(!isOpen);

  return (
    <Styled.ChatboxGroup>
      <Button
        hasIconOnly
        iconDescription="Camunda AI"
        tooltipAlignment="end"
        kind="secondary"
        size="sm"
        renderIcon={() => {
          return isOpen ? <ChevronDown /> : <AISparkle />;
        }}
        onClick={toggleChatboxOpen}
      />
    </Styled.ChatboxGroup>
  );
}
