/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, Link, TextInput } from '@carbon/react';

import { idpProjectStore } from 'stores';
import { IDP_PROJECT_EXTRACTION_METHODS } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';

import * as Styled from './../CreationModal.styled';
import { DropdownMenuItemTitle } from './../CreationModal.styled';

function ExtractionProject() {
  const [name, setName] = useState('');
  const [extractionMethod, setExtractionMethod] = useState(IDP_PROJECT_EXTRACTION_METHODS[1].value);

  const hasValidIdpProjectName = name.trim().length;

  const closeModal = () => {
    idpProjectStore.setIsExtractionProjectCreationModalVisible(false);
    setName('');
  };

  const performRequest = async () => {
    if (!hasValidIdpProjectName) {
      return;
    }

    await idpProjectStore.finalizeCreation(name, 'EXTRACTION', extractionMethod);
    closeModal();
  };

  return (
    <Styled.IdpProjectModal
      open={idpProjectStore.isExtractionProjectCreationModalVisible}
      modalHeading="Create an extraction project"
      primaryButtonText="Create"
      primaryButtonDisabled={!hasValidIdpProjectName}
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={closeModal}
    >
      <Styled.IdpProjectModalContent>
        <span>
          Upload sample documents, set up extraction fields, and annotate documents to enable accurate data and
          extraction.&nbsp;
          <Link href="" target="_blank" rel="noreferrer">
            Learn more about extraction
          </Link>
          .
        </span>
        <TextInput
          autoComplete="off"
          data-modal-primary-focus
          enableCounter
          id="idp-project-name"
          labelText="Name"
          placeholder="The name should reflect the project document type. i.e. Invoice"
          value={name}
          maxCount={100}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <Dropdown
          id="idp-project-extraction-method"
          titleText="Extraction method"
          helperText="Choose the extraction method based on your document structure"
          initialSelectedItem={IDP_PROJECT_EXTRACTION_METHODS[1]}
          label="Extraction method"
          aria-label="Extraction method"
          onChange={({ selectedItem }) => {
            setExtractionMethod(selectedItem.value);
          }}
          items={IDP_PROJECT_EXTRACTION_METHODS}
          itemToString={(item) => (item ? item.title : '')}
          itemToElement={(item) => (
            <>
              <DropdownMenuItemTitle>{item.title}</DropdownMenuItemTitle>
              <br />
              <span>{item.description}</span>
            </>
          )}
        />
      </Styled.IdpProjectModalContent>
    </Styled.IdpProjectModal>
  );
}

export default observer(ExtractionProject);
