/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanels, Tabs } from '@carbon/react';

import * as Shared from 'App/Pages/Project/Shared.styled';
import { idpApplicationStore, idpProjectStore } from 'stores';
import { EmptyState, Spinner } from 'primitives';
import { SadFace } from 'icons';
import UploadDocuments from 'App/Pages/Project/IdpApplication/IdpProject/UploadDocuments';
import { IDP_PROJECT_EXTRACTION_METHODS } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';

import { ClusterNotification } from './cluster-notification';
import IdpProjectHeader from './IdpProjectHeader';
import * as Styled from './IdpProject.styled';

const IdpProjectPage = () => {
  const [loading, setLoading] = useState(true);

  const { slug } = useParams();
  const { idpProject } = idpProjectStore;
  const { idpApplication } = idpApplicationStore;

  const reset = () => {
    idpProjectStore.reset();
  };

  useEffect(() => {
    const init = async () => {
      await idpProjectStore.init(slug.split('--')[0]);
      setLoading(false);
    };

    init();

    return () => reset();
  }, [slug]);

  const IdpProjectNotFound = () => {
    return (
      <EmptyState
        title="IDP project not found!"
        description="Sorry, your IDP project might have been deleted."
        icon={<SadFace width="48" height="48" />}
      />
    );
  };

  const getIdpProjectExtractionMethod = (value) => {
    return IDP_PROJECT_EXTRACTION_METHODS.find((method) => method.value === value)?.title;
  };

  const handleUpload = () => {
    // TODO: Handle document upload
  };

  return (
    <>
      <IdpProjectHeader />
      {loading ? (
        <Spinner fullHeight />
      ) : idpProject ? (
        <Styled.Container>
          <Styled.TitleContainer>
            <Shared.Title data-test="idp-project-name">{idpProject.name}</Shared.Title>
            <Styled.Subtitle data-test="idp-project-extractionMethod">
              {getIdpProjectExtractionMethod(idpProject.extractionMethod)}
            </Styled.Subtitle>
          </Styled.TitleContainer>
          <Styled.TabsContainer>
            <Tabs>
              <TabList aria-label="IDP project tabs" data-test="idp-project-tabs" activation="manual">
                <Tab role="tab">Upload documents</Tab>
                <Tab role="tab">Extract data</Tab>
                <Tab role="tab">Evaluate extraction</Tab>
                <Tab role="tab">Publish</Tab>
              </TabList>
              <TabPanels>
                <Styled.TabPanel data-test="upload-documents-tab-panel">
                  {<UploadDocuments onUpload={handleUpload} />}
                </Styled.TabPanel>
                <Styled.TabPanel data-test="extract-data-tab-panel">
                  {idpApplication?.clusterId && <ClusterNotification clusterId={idpApplication.clusterId} />}
                </Styled.TabPanel>
                <Styled.TabPanel data-test="evaluate-extraction-tab-panel">
                  {idpApplication?.clusterId && <ClusterNotification clusterId={idpApplication.clusterId} />}
                </Styled.TabPanel>
                <Styled.TabPanel data-test="publish-tab-panel" />
              </TabPanels>
            </Tabs>
          </Styled.TabsContainer>
        </Styled.Container>
      ) : (
        <IdpProjectNotFound />
      )}
    </>
  );
};

export default observer(IdpProjectPage);
