/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Service } from 'services';

export const GitOperationStatus = {
  UP_TO_DATE: 'up_to_date',
  SUCCESS: 'successful',
  CONFLICT: 'conflict',
  ERROR: 'error',
  TIMEOUT: 'timeout'
};

class GitSyncService extends Service {
  #GIT_SYNC_PULL_ENDPOINT_REGEX = /\/internal-api\/process-applications\/[^/]+\/pull/;
  #GIT_SYNC_PUSH_ENDPOINT_REGEX = /\/internal-api\/process-applications\/[^/]+\/push/;
  LONG_STANDING_ENDPOINTS = [this.#GIT_SYNC_PULL_ENDPOINT_REGEX, this.#GIT_SYNC_PUSH_ENDPOINT_REGEX];

  /**
   * @typedef {Object} BaseGitSettings
   * @property {string} clientId
   * @property {string} installationId
   * @property {string} repositoryUrl
   * @property {string} branchName
   */

  /**
   * @typedef {BaseGitSettings & { pemKey: boolean}} GitHubSettings
   */

  /**
   * @typedef {BaseGitSettings & { projectAccessToken: boolean }} GitLabSettings
   */

  /**
   * @typedef {GitHubSettings | GitLabSettings} GitSettings
   */

  /**
   * @typedef {Object} GitSyncResponse
   * @property {string} status
   * @property {string} [message]
   * @property {object} [data]
   */

  /**
   * Returns the settings
   * @param {string} processApplicationId
   * @returns {Promise<GitSettings>}
   */
  async getSettings(processApplicationId) {
    return this.get(`/internal-api/process-applications/${processApplicationId}/settings`);
  }

  /**
   * Saves the settings
   * @param processApplicationId - The process application id
   * @param settings - The settings
   * @returns GitSyncResponse
   */
  async saveSettings({ processApplicationId, settings }) {
    const _settings = this.#nullifyEmptyStrings({ ...settings });

    try {
      // Remove pemKey if it is not a string, so that the server
      // does not try to save it again
      if (typeof _settings.pemKey !== 'string') {
        delete _settings.pemKey;
      }
      if (typeof _settings.projectAccessToken !== 'string') {
        delete _settings.projectAccessToken;
      }

      await this.put(`/internal-api/process-applications/${processApplicationId}/settings`, _settings);
      return { status: GitOperationStatus.SUCCESS };
    } catch (error) {
      return this.#getError(error);
    }
  }

  /**
   * Pulls the changes
   * @param processApplicationId - The process application id
   * @param force - Whether to force the pull
   * @returns GitSyncResponse
   */
  async pull({ processApplicationId, force }) {
    try {
      let url = `/internal-api/process-applications/${processApplicationId}/pull`;
      if (force) {
        url += '?force=true';
      }

      const { pulled, mainProcessFileId } = await this.post(url);

      if (!pulled) {
        return { status: GitOperationStatus.UP_TO_DATE, data: { mainProcessFileId } };
      }

      return { status: GitOperationStatus.SUCCESS, data: { mainProcessFileId } };
    } catch (error) {
      return this.#getError(error);
    }
  }

  /**
   * Pushes the changes
   * @param processApplicationId - The process application id
   * @returns GitSyncResponse
   */
  async push(processApplicationId) {
    try {
      await this.post(`/internal-api/process-applications/${processApplicationId}/push`);
      return { status: GitOperationStatus.SUCCESS };
    } catch (error) {
      return this.#getError(error);
    }
  }

  async deleteConnection(processApplicationId) {
    try {
      await this.delete(`/internal-api/process-applications/${processApplicationId}/settings`);
      return { status: GitOperationStatus.SUCCESS };
    } catch (error) {
      return this.#getError(error);
    }
  }

  /**
   * Returns the error object
   * @param error
   * @returns GitSyncResponse
   */
  #getError = (error) => {
    if (error.status === 409) {
      return { status: GitOperationStatus.CONFLICT };
    }

    if (error.status === 408) {
      return { status: GitOperationStatus.TIMEOUT };
    }

    return { status: GitOperationStatus.ERROR, message: error.reason || (error && error[0]?.detail) };
  };

  /**
   * Nullify empty strings in settings. This is necessary because the server
   * does not accept empty strings for certain fields.
   * @param {Object} settings  - The settings
   * @returns {Object} - The settings with empty strings nullified
   */
  #nullifyEmptyStrings = (settings) =>
    Object.entries(settings).reduce((acc, [key, value]) => {
      acc[key] = value === '' ? null : value;
      return acc;
    }, {});
}

export default new GitSyncService();
