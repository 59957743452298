/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { FileUploaderButton } from '@carbon/react';

import { EmptyState } from 'primitives';
import { EntityTable } from 'components';
import { IdpProjectDocumentUpload } from 'icons';

import * as Styled from './UploadDocuments.styled';

const UploadDocuments = ({ onUpload }) => {
  const UploadButton = () => {
    return (
      <FileUploaderButton
        multiple
        disableLabelChanges
        labelText="Upload documents"
        size="lg"
        accept={['.pdf']}
        onChange={(event) => onUpload(event.target.files)}
      />
    );
  };

  return (
    <EntityTable
      noTablePadding
      action={UploadButton}
      emptyState={
        <EmptyState
          icon={<IdpProjectDocumentUpload width="96" height="96" />}
          title="Upload documents to get started"
          description={
            <Styled.EmptyStateDescriptionContainer>
              <Styled.EmptyStateText>
                <b>Upload sample PDFs</b>
                <span>
                  Upload documents that represent the specific type you need (e.g., invoices). Make sure the files are
                  in PDF format and not password protected.
                </span>
              </Styled.EmptyStateText>
              <Styled.EmptyStateText>
                <b>Cover the full taxonomy</b>
                <span>
                  Upload enough documents to represent all taxonomy fields. If a single document doesn't cover all, use
                  multiple documents.
                </span>
              </Styled.EmptyStateText>
              <Styled.EmptyStateText>
                <b>Include format variations</b>
                <span>
                  Provide examples of different formats, field locations, or data presentations (e.g., data formats or
                  positions).
                </span>
              </Styled.EmptyStateText>
            </Styled.EmptyStateDescriptionContainer>
          }
          action={<UploadButton />}
        />
      }
      columns={[]}
      toolbarPlaceholder="Search document"
      rows={[]}
    />
  );
};

export default UploadDocuments;
