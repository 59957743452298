/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable, runInAction } from 'mobx';

import gitSyncStore, { GitSyncStatus } from 'features/git-sync/sync/GitSyncStore';
import gitSyncService, { GitOperationStatus } from 'features/git-sync/GitSyncService';
import GitSyncTrackingEvents from 'features/git-sync/GitSyncTrackingEvents';
import { DEFAULT_GIT_PROVIDER, SupportedGitProviders } from 'features/git-sync/constants';
import { processApplicationStore } from 'stores';
import { notificationStore } from 'components/NotificationSystem';

export const GIT_SETTINGS_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  FINISHED: 'finished',
  ERROR: 'error'
};

class GitSettingsStore {
  status = GIT_SETTINGS_STATUS.INACTIVE;
  settings = {};
  localSettingsList = [];
  shouldShowDialog = false;
  shouldShowDeleteDialog = false;
  shouldInitiateSyncAfterSetup = false;
  initialSettings = {};

  constructor() {
    makeAutoObservable(this);
  }

  init(processApplicationId) {
    this.#fetchSettings(processApplicationId);
  }

  reset() {
    this.shouldShowDialog = false;
    this.shouldShowDeleteDialog = false;
    this.resetSettings();
  }

  resetSettings() {
    this.shouldInitiateSyncAfterSetup = false;
    this.settings = {};
    this.localSettingsList = [];
    this.initialSettings = {};
    this.status = GIT_SETTINGS_STATUS.INACTIVE;
  }

  open() {
    this.shouldShowDialog = true;
  }

  close() {
    this.shouldShowDialog = false;
    this.status = GIT_SETTINGS_STATUS.INACTIVE;
    this.localSettingsList = [];
  }

  async save() {
    runInAction(() => {
      this.status = GIT_SETTINGS_STATUS.ACTIVE;
    });

    try {
      if (!this.settings.hasOwnProperty('provider')) {
        this.settings.provider = DEFAULT_GIT_PROVIDER;
      }

      const { status, message } = await gitSyncService.saveSettings({
        processApplicationId: processApplicationStore.processApplication.id,
        settings: this.settings
      });

      switch (status) {
        case GitOperationStatus.SUCCESS:
          runInAction(() => {
            this.status = GIT_SETTINGS_STATUS.FINISHED;
            this.localSettingsList = [];
            this.initialSettings = { ...this.settings };
          });

          GitSyncTrackingEvents.trackGitConnectionSetup();

          setTimeout(() => {
            this.close();
            this.#handleForcedSync();
          }, 1000);
          break;

        case GitOperationStatus.ERROR:
          throw new Error(message || 'Something went wrong while saving Git settings.');

        case GitOperationStatus.TIMEOUT:
          throw new Error('The operation timed out, please try again.');

        default:
          throw new Error('Something went wrong while saving Git settings.');
      }
    } catch (error) {
      console.error('Error saving settings', error);
      notificationStore.showError('Something went wrong while saving Git settings');

      runInAction(() => {
        this.status = GIT_SETTINGS_STATUS.ERROR;
      });
    }
  }

  async changeProvider(provider) {
    this.resetSettings();
    await this.#fetchSettings(processApplicationStore.processApplication.id);
    this.storeSetting('provider', provider);
  }

  storeSetting(key, value) {
    if (value === this.initialSettings[key]) {
      if (this.localSettingsList.includes(key)) {
        runInAction(() => {
          this.settings[key] = value;
          this.localSettingsList = this.localSettingsList.filter((setting) => setting !== key);
        });

        this.#computeShouldInitiateSyncAfterSetup();
      }
      return;
    }

    runInAction(() => {
      this.settings[key] = value;

      if (!this.localSettingsList.includes(key)) {
        this.localSettingsList.push(key);
      }

      if (!this.shouldInitiateSyncAfterSetup) {
        this.#computeShouldInitiateSyncAfterSetup();
      }
    });
  }

  #computeShouldInitiateSyncAfterSetup() {
    runInAction(() => {
      this.shouldInitiateSyncAfterSetup = this.localSettingsList.some((setting) =>
        this.providerEntity.fieldsThatRequireResync.includes(setting)
      );
    });
  }

  isSettingSaved(key) {
    return !this.localSettingsList.includes(key);
  }

  getCurrentSetting(key) {
    return this.settings[key] || '';
  }

  showDeleteConnection() {
    this.shouldShowDialog = false;
    this.shouldShowDeleteDialog = true;
  }

  async deleteConnection() {
    this.status = GIT_SETTINGS_STATUS.ACTIVE;
    try {
      await gitSyncService.deleteConnection(processApplicationStore.processApplication.id);
      this.reset();
    } catch (error) {
      console.error('Error deleting connection', error);
      notificationStore.showError('Something went wrong while deleting Git connection');

      runInAction(() => {
        this.status = GIT_SETTINGS_STATUS.ERROR;
      });
    }
  }

  hideDeleteConnection() {
    this.shouldShowDialog = true;
    this.shouldShowDeleteDialog = false;
  }

  get hasUnsavedSettings() {
    return this.localSettingsList.length > 0;
  }

  get hasAllRequiredSettings() {
    let requiredSettings = [];
    for (const key in this.providerEntity.validationRules) {
      const field = this.providerEntity.validationRules[key];
      if (field.required) {
        requiredSettings.push(key);
      }
    }
    return requiredSettings.every((setting) => Object.keys(this.initialSettings).includes(setting));
  }

  get hasErrors() {
    return this.status === GitSyncStatus.ERROR;
  }

  get hasSaved() {
    return this.status === GitSyncStatus.FINISHED;
  }

  #getRepositoryPath({ omitProvider, omitBranch } = { omitProvider: false, omitBranch: false }) {
    const { repositoryUrl, branchName, path: subPath } = this.settings;

    if (!repositoryUrl || !branchName) {
      return '';
    }

    let baseUrl = repositoryUrl;

    if (!omitBranch) {
      const { branchPathPattern } = this.providerEntity;
      baseUrl = branchPathPattern.replace(':repository', repositoryUrl).replace(':branch', branchName);
    }

    if (omitProvider) {
      baseUrl = baseUrl.replace(this.providerEntity.providerBaseUrl, '');
    }

    const out = subPath ? `${baseUrl}/${subPath}` : baseUrl;
    return out.replace(/^\//, '');
  }

  get configuredProvider() {
    return this.settings.provider || DEFAULT_GIT_PROVIDER;
  }

  get providerEntity() {
    const provider = SupportedGitProviders[this.configuredProvider];
    if (!provider) {
      throw new Error(`Unsupported Git provider was given ${this.configuredProvider}`);
    }
    return provider;
  }

  get providerLabel() {
    return this.providerEntity.label;
  }

  get providerIcon() {
    return this.providerEntity.icon;
  }

  get fullRepositoryPath() {
    return this.#getRepositoryPath();
  }

  get shortRepositoryPath() {
    const { path } = this.settings;

    if (path) {
      return this.#getRepositoryPath({ omitProvider: true, omitBranch: false });
    } else {
      return this.#getRepositoryPath({ omitProvider: true, omitBranch: true });
    }
  }

  get isFullRepositoryPathValid() {
    const { repositoryUrl, branchName } = this.settings;
    return Boolean(repositoryUrl && branchName);
  }

  async #fetchSettings(processApplicationId) {
    try {
      let settings = await gitSyncService.getSettings(processApplicationId);

      if (Object.keys(settings).length === 1 && settings.pemKey === false) {
        // We receive such a payload when there are no settings configured yet.
        // To avoid side-effects we consider it empty.
        settings = {};
      }

      runInAction(async () => {
        this.settings = { ...settings };
        this.initialSettings = { ...settings };
      });
    } catch (error) {
      console.error('Error fetching settings', error);
      notificationStore.showError('Something went wrong while fetching Git settings');
    }
  }

  #handleForcedSync() {
    if (this.shouldInitiateSyncAfterSetup) {
      gitSyncStore.open({ force: true });

      runInAction(() => {
        this.shouldInitiateSyncAfterSetup = false;
      });
    }
  }
}

export default new GitSettingsStore();
