/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

import { clusterService, authService } from 'services';
import { clustersStore, organizationStore } from 'stores';

export default function useReloadClustersOnClusterUpdateSaaS(clusterId) {
  const { currentOrganizationId } = organizationStore;

  useEffect(() => {
    if (!authService.token) {
      return;
    }

    const stream = clusterService.getEventStream(authService.token);

    stream.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.org !== currentOrganizationId || data.entity !== 'cluster' || data.clusterId !== clusterId) {
        return;
      }

      if (data.type === 'updated') {
        clustersStore.loadClusters();
      }
    };

    return () => {
      stream?.close();
    };
  }, [currentOrganizationId, authService.token]);
}
