/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const IDP_PROJECT_TYPE = {
  EXTRACTION: 'EXTRACTION',
  DOCUMENT_AUTOMATION: 'DOCUMENT_AUTOMATION'
};
export const IDP_PROJECT_EXTRACTION_METHODS = [
  {
    id: 'form-extraction',
    title: 'Form Extraction',
    value: 'FORM',
    description: 'Extract data from documents with a consistent layout.',
    disabled: true
  },
  {
    id: 'unstructured-data-extraction',
    title: 'Unstructured data extraction',
    value: 'UNSTRUCTURED_DATA',
    description: 'Extract Unstructured data into structured formats.',
    disabled: false
  }
];
