/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// File contains the hard-coded version of the client and webapp, will be
// overwritten by CI build, do not move or modify manually!
export const version = '0a04f518a18b9cb8d97cfe7d54034b478d04a6f4';

export const versionShort = (() => {
  return version.substring(0, 7);
})();
