/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import isEqual from 'lodash/isEqual';
import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil';

import { getExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';
import { inboundConnectorStore } from 'App/Pages/Diagram/stores';

import getInboundConnectorType from './getInboundConnectorType';
import getInboundConnectorId from './getInboundConnectorId';

/*
 * Identifies if the selected element has an inbound connector configured and
 * stores the inbound connector type to an observable field accessible within Diagram page.
 * Also, set the process ID of the parent process in a non-observable field.
 */
export default function DetectInboundConnector(eventBus) {
  const onChange = (element) => {
    if (element) {
      markAsDirtyIfPropertiesChanged(element);
      updateStore(element);
    }
  };

  eventBus.on('canvas.destroy', () => {
    inboundConnectorStore.reset();
  });

  eventBus.on('selection.changed', (event) => {
    onChange(event.newSelection[0]);
  });

  eventBus.on('commandStack.propertiesPanel.zeebe.changeTemplate.postExecute', (e) => {
    // We probably had a concurring issue, because `getInboundConnectorId` was retrieving and empty string as `inboundId`. This was happening only here and only for the first time. Adding a slight delay solved the issue.
    setTimeout(() => {
      onChange(e.context?.element);
    }, 250);
  });

  eventBus.on('element.changed', (event) => {
    onChange(event.element);
  });
}

const markAsDirtyIfPropertiesChanged = (element) => {
  if (element) {
    const bo = getBusinessObject(element);
    if (!bo) {
      return;
    }
    const extensionElement = getExtensionElement(bo, 'zeebe:Properties');
    const currentElementProperties = extensionElement?.properties;
    if (currentElementProperties?.length > 0) {
      // we do not always have extensionElement (e.g. if we do not have any connector template selected)
      const possiblePreviousElementProperties = inboundConnectorStore.elementsProperties.get(element.id);
      if (
        possiblePreviousElementProperties?.length > 0 &&
        !isEqual(possiblePreviousElementProperties, currentElementProperties)
      ) {
        // if the properties are different than the previous ones (when we deployed), mark it as dirty
        inboundConnectorStore.markAsDirty(element.id);
      }
      // store the current properties to be able to compare it later
      inboundConnectorStore.addElementProperties(element.id, currentElementProperties);
    }
  }
};

const getParentProcessId = (element) => {
  const { parent } = element;

  if (!parent) {
    return null;
  }

  if (is(parent, 'bpmn:Process')) {
    return parent.id;
  }

  if (is(parent, 'bpmn:SubProcess')) {
    const rootElement = getRootElement(parent);
    return rootElement ? rootElement.id : null;
  }

  if (is(parent, 'bpmn:Participant')) {
    return getBusinessObject(parent).processRef.id;
  }

  return null;
};

function getRootElement(element) {
  if (!element) {
    return;
  }
  const businessObject = getBusinessObject(element);
  let parent = businessObject;

  while (parent.$parent && !is(parent, 'bpmn:Process')) {
    parent = parent.$parent;
  }

  return parent;
}

const updateStore = (element) => {
  const inboundType = getInboundConnectorType(element);
  const inboundId = getInboundConnectorId(element);
  const parentProcessId = element ? getParentProcessId(element) : null;
  const elementId = element ? element.id : null;

  inboundConnectorStore.setInboundConnectorType(inboundType);
  inboundConnectorStore.setInboundConnectorId(inboundId);
  inboundConnectorStore.setParentProcessId(parentProcessId);
  inboundConnectorStore.setSelectedElementId(elementId);
};

DetectInboundConnector.$inject = ['eventBus'];
