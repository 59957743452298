/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { CloudDownload, Launch } from '@carbon/icons-react';
import { MenuItemDivider } from '@carbon/react';

import * as Styled from './DiscoverConnectorsModal.styled';

export const DiscoverConnectorsModalConnector = ({ connector, handleImportConnector }) => {
  return (
    <Styled.ConnectorTile>
      <Styled.ConnectorTileContent>
        <Styled.ResourceLogoRow>
          <Styled.ResourceLogo src={connector.iconUrl} alt={`${connector.name} logo`} />
        </Styled.ResourceLogoRow>
        <Styled.ConnectorNameRow>{connector.name}</Styled.ConnectorNameRow>
        <Styled.ConnectorCreatedByRow>By {connector.creatorName}</Styled.ConnectorCreatedByRow>
        <Styled.ConnectorDescriptionRow>
          <Styled.EllipsisText>{connector.description}</Styled.EllipsisText>
        </Styled.ConnectorDescriptionRow>
        <Styled.Row>
          <Styled.DocumentationLink href={connector.documentationUrl} target="_blank">
            Documentation
            <Styled.ResourceLink>
              <Launch />
            </Styled.ResourceLink>
          </Styled.DocumentationLink>
        </Styled.Row>
      </Styled.ConnectorTileContent>
      <Styled.ConnectorTileFooter>
        {connector.selfHosted === true ? (
          <>
            <Styled.MenuItemDividerRow>
              <MenuItemDivider />
            </Styled.MenuItemDividerRow>
            <div>
              <Styled.SelfHostedLink
                href={'https://docs.camunda.io/docs/guides/host-custom-connectors/'}
                target="_blank"
              >
                Learn more about self-hosted connectors
                <Styled.ResourceLink>
                  <Launch />
                </Styled.ResourceLink>
              </Styled.SelfHostedLink>
            </div>
          </>
        ) : (
          <Styled.ConnectorCTAButton
            kind="tertiary"
            renderIcon={CloudDownload}
            onClick={() => {
              handleImportConnector(connector.id, connector.iconUrl);
            }}
            iconDescription="Download to project"
          >
            Download to project
          </Styled.ConnectorCTAButton>
        )}
      </Styled.ConnectorTileFooter>
    </Styled.ConnectorTile>
  );
};

export default observer(DiscoverConnectorsModalConnector);
