/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { TopBar, Breadcrumb } from 'components';
import { projectStore } from 'stores';
import buildSlug from 'utils/buildSlug';

export const Header = ({ processAppId, processAppName }) => {
  return (
    <TopBar.Breadcrumbs>
      <>
        <Breadcrumb data-test="breadcrumb-home" title="Home" variant="link" to="/" />
        <Breadcrumb
          title={projectStore.project?.name}
          variant="link"
          data-test="breadcrumb-create-new-element-template"
          to={`/projects/${projectStore.project?.id}`}
        />
        {processAppId && (
          <Breadcrumb
            title={processAppName}
            variant="link"
            to={`/process-applications/${buildSlug(processAppId, processAppName)}`}
          />
        )}
        {
          <Breadcrumb
            data-test="breadcrumb-connector-template"
            title="Create new connector template"
            variant="text"
            isBold
          />
        }
      </>
    </TopBar.Breadcrumbs>
  );
};

export default observer(Header);
