/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import { breadcrumbStore, idpApplicationStore, idpProjectStore, projectStore } from 'stores';
import { Breadcrumb, Dropdown, TopBar } from 'components';
import hasAccess, { actions } from 'utils/user-access';
import { getPageTitle } from 'utils/helpers';
import history from 'utils/history';
import buildSlug from 'utils/buildSlug';

const IdpProjectHeader = () => {
  const { idpProject, loading } = idpProjectStore;
  const { idpApplication } = idpApplicationStore;
  const { project } = projectStore;
  const pageTitle = loading ? 'Loading...' : idpProject?.name || 'IDP project not Found';

  const handleDelete = async () => {
    breadcrumbStore.toggleDropdownVisibility();

    if (await idpProjectStore.delete(idpProject.id)) {
      history.push(`/idp-applications/${buildSlug(idpApplication.id, idpApplication.name)}`);
    }
  };

  const title = idpProject?.name || 'Loading...';
  return (
    <Fragment>
      <Helmet>
        <title>{getPageTitle(pageTitle)}</title>
      </Helmet>

      <TopBar.Breadcrumbs>
        <Breadcrumb title="Home" variant="link" to="/" data-test="breadcrumb-home" />
        <Breadcrumb
          title={project.name || 'Loading...'}
          variant="link"
          to={`/projects/${buildSlug(project.id, project.name)}`}
        />
        <Breadcrumb
          title={idpApplication?.name || 'Loading...'}
          variant="link"
          to={`/idp-applications/${buildSlug(idpApplication?.id, idpApplication?.name)}`}
        />
        {hasAccess(project, actions.MODIFY_DIAGRAM) ? (
          <Breadcrumb
            title={title}
            variant="dropdown"
            isBold
            forPage="idp-project"
            /*TODO: Uncomment when rename IDP project endpoint is ready.*/
            /*handleSubmit={async (name) => {
              breadcrumbStore.finishEditing();
              await idpProjectStore.rename(name);
            }}*/
          >
            <Dropdown.ListItem
              key="edit"
              /*TODO: Remove disabled when rename IDP project endpoint is ready.*/
              disabled
              onClick={() => {
                breadcrumbStore.toggleDropdownVisibility();
                breadcrumbStore.toggleEditingFor('idp-project');
              }}
              data-test="rename-idp-project"
            >
              Edit name
            </Dropdown.ListItem>
            <Dropdown.ListItem key="delete" onClick={handleDelete} data-test="delete-idp-project">
              Delete
            </Dropdown.ListItem>
          </Breadcrumb>
        ) : (
          <Breadcrumb title={title} variant="text" isBold data-test="breadcrumb-idp-project" />
        )}
      </TopBar.Breadcrumbs>
    </Fragment>
  );
};

export default observer(IdpProjectHeader);
