/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Tab, Tabs, Tooltip as CarbonTooltip } from '@carbon/react';

import { AvatarList, Switch } from 'primitives';
import {
  currentDiagramStore,
  diagramExtensionStore,
  realtimeCollaborationStore,
  userStore,
  processApplicationStore
} from 'stores';
import {
  dedicatedModesStore,
  isModesTooltipVisibleStore,
  executeModalStore,
  isLinkingImprovementsModalVisibleStore
} from 'App/Pages/Diagram/stores';
import { XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';
import { Danger, Info, Saved } from 'icons';
import hasAccess, { actions } from 'utils/user-access';
import { isBPMN as isBPMNUtil, isDMN as isDMNUtil } from 'utils/helpers';
import config from 'utils/config';
import { ReadOnlyPill } from 'components';
import { MODES_INDEXES } from 'App/Pages/Diagram/stores/DedicatedModesStore';
import {
  ClusterRequiredNotification,
  checkIfNoClusterPresent,
  showClusterRequiredNotification
} from 'features/cluster-required-notification';

import DeploymentActions from './Deployment/DeploymentActions';
import * as Styled from './ActionBar.styled';
import tokenSimulationStore from './BpmnJSExtensions/tokenSimulationExtension/TokenSimulationStore';
import ActionsMenu from './ActionsMenu';
import ExecuteModal from './Deployment/executeModal';
import usePlayC8Enabled from './hooks/usePlayC8Enabled';

const getStatusIcon = (status) => {
  switch (status) {
    case 'progress':
      return <Styled.SavingIcon width="16" height="16" />;
    case 'done':
      return <Saved width="20" height="20" />;
    case 'warning':
      return <Danger width="18" height="18" />;
    case 'info':
      return <Info width="14" height="14" />;
    default:
      return <Info width="14" height="14" />;
  }
};

const useTokenSimulation = () => {
  const { isTokenSimulationOn, onTokenSimulationChange } = tokenSimulationStore;

  tokenSimulationStore.init();

  useEffect(() => {
    return () => {
      tokenSimulationStore.reset();
    };
  }, []);

  return { isTokenSimulationOn, onTokenSimulationChange };
};

const useModesTooltip = () => {
  useEffect(() => {
    const handleClick = () => {
      isModesTooltipVisibleStore.hide();
    };

    document.body.addEventListener('click', handleClick, true);

    return () => {
      document.body.removeEventListener('click', handleClick, true);
    };
  }, []);
};

/**
 * This custom hook gets all the aria-controls related to the buttons present in the ref DOM container
 * We are using this hook for achieving a11y compatibility (ref. https://github.com/camunda/web-modeler/issues/6533#issuecomment-1971373343)
 * @param ref DOM element where to find all the buttons
 * @param className used for filtering the buttons
 * @return {string[]} the aria-controls related to the buttons present in the given ref
 */
const useAriaControls = (ref, className) => {
  const [ariaControls, setAriaControls] = useState([]);

  useEffect(() => {
    if (!ref || !ref.current) return;

    const buttons = ref.current.querySelectorAll(`button.${className}`);

    const controls = Array.from(buttons).map((button) => button.getAttribute('aria-controls'));

    setAriaControls(controls);
  }, [ref, className]);

  return ariaControls;
};

const MODES_TABS_CLASSNAME = 'mode-tab';

const ActionBar = ({ shouldShowModesTooltip }) => {
  const actionBarContainerRef = useRef(null);
  const ariaControls = useAriaControls(actionBarContainerRef, MODES_TABS_CLASSNAME);
  const history = useHistory();
  const { autosaveMessage, isValid: isCurrentDiagramValid } = currentDiagramStore;
  const { diagram, project } = currentDiagramStore.state;
  const {
    selectedModeIndex,
    setViewModeIndex,
    allowedViewModes,
    hasPlayMode,
    isDesignMode,
    isImplementMode,
    updateModesAriaControls
  } = dedicatedModesStore;
  const collaborators = realtimeCollaborationStore.onlineCollaborators.filter(
    (collaborator) => collaborator.id !== userStore.userId
  );
  const { shouldBeShown: isModesTooltipVisible } = isModesTooltipVisibleStore;
  const { isEditorOpen, invalidContentStatusMessage } = XMLEditorStore;

  const { isTokenSimulationOn, onTokenSimulationChange } = useTokenSimulation();

  const isBPMN = diagram && isBPMNUtil(diagram);
  const isDMN = diagram && isDMNUtil(diagram);

  const isDeploymentEnabled = isBPMN ? config?.zeebe?.bpmnDeploymentEnabled : config?.zeebe?.dmnDeploymentEnabled;

  const shouldShowDeploymentActions =
    isDeploymentEnabled && ((isBPMN && isImplementMode) || isDMN) && hasAccess(project, actions.MODIFY_DIAGRAM);
  const shouldShowShareButton = ((isBPMN && isDesignMode) || isDMN) && hasAccess(project, actions.SHARE_DIAGRAM);
  const shareButtonVariant = isDMN ? 'secondary' : 'primary';
  const shouldShowNavigationModes = isCurrentDiagramValid && isBPMN && allowedViewModes?.length > 0;

  const isSelectedModeAvailable = allowedViewModes.some(({ index }) => index === selectedModeIndex);

  const selectedIndex = isSelectedModeAvailable ? selectedModeIndex : 0;
  const { isVisible: isExecuteModalVisible, mode: executeModalMode, setMode: setExecuteModalMode } = executeModalStore;
  const { fromAProcessApplication } = processApplicationStore;
  const { show: showImprovementsModal } = isLinkingImprovementsModalVisibleStore;

  const isPlayC8Enabled = usePlayC8Enabled();

  useEffect(() => {
    updateModesAriaControls(ariaControls);
  }, [ariaControls]);

  const modesTabs = (
    <Tabs
      selectedIndex={selectedIndex}
      onChange={async (evt) => {
        await currentDiagramStore.saveLatestDiagramState();

        if (evt.selectedIndex === MODES_INDEXES.PLAY && isPlayC8Enabled) {
          if (checkIfNoClusterPresent()) {
            showClusterRequiredNotification();
            return;
          }

          executeModalStore.setMode('play');
        } else {
          setViewModeIndex(evt.selectedIndex);
        }
      }}
    >
      <Styled.TabList aria-label="List of modes" activation="manual">
        {allowedViewModes.map((viewMode) => (
          <Tab
            className={MODES_TABS_CLASSNAME}
            key={`view-mode-${viewMode.index}`}
            title={viewMode.label}
            data-test={`mode-tab-${viewMode.label.toLowerCase()}`}
          >
            {viewMode.label}
          </Tab>
        ))}
      </Styled.TabList>
    </Tabs>
  );

  useModesTooltip();

  const renderableAutosaveMessage = invalidContentStatusMessage || autosaveMessage;

  return (
    <Styled.ActionBarContainer data-test="action-bar" ref={actionBarContainerRef}>
      <>
        <Styled.ActionBar>
          <Styled.Left>
            {shouldShowNavigationModes &&
              (shouldShowModesTooltip && isModesTooltipVisible ? (
                <CarbonTooltip
                  data-test="modes-tooltip"
                  label={
                    hasPlayMode
                      ? 'Switch modes to design, implement or simulate a process in play mode.'
                      : 'Switch modes easily between designing and implementing a process.'
                  }
                  align="bottom-start"
                  defaultOpen={isModesTooltipVisible}
                >
                  {modesTabs}
                </CarbonTooltip>
              ) : (
                modesTabs
              ))}

            {Boolean(renderableAutosaveMessage) && (
              <Styled.Status className={renderableAutosaveMessage.status} data-test="autosave">
                {getStatusIcon(renderableAutosaveMessage.status)}
                {renderableAutosaveMessage.message}
              </Styled.Status>
            )}
          </Styled.Left>

          <Styled.PaddedContent>
            {currentDiagramStore.isCommenterOrViewer && <ReadOnlyPill />}

            {isBPMN && isDesignMode && (
              <Styled.Label>
                <Switch checked={isTokenSimulationOn} onChange={onTokenSimulationChange} data-test="token-simulation" />
                Token simulation
              </Styled.Label>
            )}

            {hasAccess(project, actions.VIEW_COLLABORATORS) && (
              <>
                <AvatarList collaborators={collaborators} />
                {collaborators.length > 0 && <Styled.Separator isDesignMode={isDesignMode} />}
              </>
            )}

            {!isEditorOpen && (
              <>
                {shouldShowDeploymentActions && <DeploymentActions />}

                {isImplementMode && !isDeploymentEnabled && (
                  <Styled.ActionButton
                    title="Show milestones history"
                    onClick={() => {
                      history.push(`/diagrams/${diagram.id}/milestones`);
                    }}
                    variant="primary"
                  >
                    History
                  </Styled.ActionButton>
                )}

                {shouldShowShareButton && (
                  <Styled.ActionButton
                    onClick={() => diagramExtensionStore.showShareModal()}
                    title="Share"
                    data-test="share"
                    variant={shareButtonVariant}
                  >
                    Share
                  </Styled.ActionButton>
                )}
              </>
            )}

            {isCurrentDiagramValid && isEditorOpen && (
              <Styled.ActionButton
                onClick={() => XMLEditorStore.closeEditor()}
                title="Back to modeling view"
                data-test="goto-modeling-view"
                variant="primary"
              >
                Back to modeling view
              </Styled.ActionButton>
            )}

            <ActionsMenu />
          </Styled.PaddedContent>
        </Styled.ActionBar>
        <ExecuteModal
          open={Boolean(isExecuteModalVisible)}
          mode={executeModalMode}
          onClose={({ hasBeenExecutedOnClusterLT8_4, shouldProceedToPlay } = {}) => {
            setExecuteModalMode(undefined);
            if (hasBeenExecutedOnClusterLT8_4) {
              // Introducing a delay between transitioning from ExecuteModal to LinkingImprovementsModal
              setTimeout(() => {
                showImprovementsModal();
              }, 800);
            }

            if (shouldProceedToPlay) {
              dedicatedModesStore.setViewModeIndex(MODES_INDEXES.PLAY);
            }
          }}
          deployTarget={fromAProcessApplication ? 'process application' : 'diagram'}
        />
        <ClusterRequiredNotification />
      </>
    </Styled.ActionBarContainer>
  );
};

export default observer(ActionBar);
