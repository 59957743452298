/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import * as Styled from './ConfigurationSavedNotification.styled';

export default function ConfigurationSavedNotification({ link }) {
  return (
    <div>
      <div>You have successfully saved a connector template!</div>
      {link && (
        <Styled.Link href={link} target="_blank" className="bold">
          View connector template
        </Styled.Link>
      )}
    </div>
  );
}
