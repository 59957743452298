/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Button, InlineNotification, Modal, Stack } from '@carbon/react';
import { useState } from 'react';
import styled from 'styled-components';

import { projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';

import gitSettingsStore from './GitSettingsStore';
import { validatePattern, validateRequired } from './fields/validations';
import ProviderSelector from './ProviderSelector';

const GitSettingsDialog = () => {
  const {
    shouldShowDialog,
    status,
    settings,
    shouldInitiateSyncAfterSetup,
    providerEntity,
    hasAllRequiredSettings,
    hasSaved,
    hasErrors: hasErrorsWhileSaving
  } = gitSettingsStore;
  const { fields: Fields, validationRules, label: providerLabel } = providerEntity;

  const [errors, setErrors] = useState({});

  const isValidRequired = (rule, value) => {
    return rule.required ? validateRequired(value) : true;
  };
  const hasInvalidField = (key, rule) =>
    !isValidRequired(rule, settings[key]) ||
    (rule.customValidation && !rule.customValidation(settings[key])) ||
    !validatePattern(rule.pattern, settings[key]);
  const project = projectStore.project;
  const canModifySettings = hasAccess(project, actions.MODIFY_GIT_SYNC_SETTINGS);
  const canSave =
    canModifySettings &&
    !Object.entries(validationRules).some(([key, rule]) => hasInvalidField(key, rule) || settings[key] === false);

  if (!Fields) {
    throw new Error('No fields found for the given git provider');
  }

  const onChangeSetting = (key, value) => {
    value = sanitizeValue(value);

    const rule = validationRules[key];

    gitSettingsStore.storeSetting(key, value);

    if (rule) {
      if (!isValidRequired(rule, value)) {
        setErrors({ ...errors, [key]: 'This field is required' });
        return;
      }

      if ((rule.customValidation && !rule.customValidation(value)) || !validatePattern(rule.pattern, value)) {
        setErrors({ ...errors, [key]: 'Invalid value' });
        return;
      }

      const newErrors = { ...errors };
      delete newErrors[key];
      setErrors(newErrors);
    }
  };

  const onInputBlur = (key, value) => {
    const rule = validationRules[key];

    if (typeof rule?.onBlur === 'function') {
      value = rule.onBlur(value);
      if (value !== settings[key]) {
        gitSettingsStore.storeSetting(key, value);
      }
    }
  };

  const sanitizeValue = (value) => {
    return typeof value === 'string' ? value.trim() : value;
  };

  const hasValidationErrors = Object.values(errors).some((error) => error);

  const getLoadingDescription = () => {
    if (hasSaved) {
      return 'Saved';
    }

    if (hasErrorsWhileSaving) {
      return 'Failed';
    }

    return 'Saving...';
  };

  const modalContent = () => {
    return (
      <Stack gap={7}>
        <ProviderSelector />

        <Fields errors={errors} onChangeSetting={onChangeSetting} onInputBlur={onInputBlur} />

        {shouldInitiateSyncAfterSetup && (
          <InlineNotification
            kind="info"
            title="Initial sync required"
            lowContrast
            hideCloseButton
            subtitle={`Configuring ${providerLabel} requires an initial sync. The sync will start once the configuration is saved.`}
          />
        )}

        <>
          <DangerZoneTitle>Danger zone</DangerZoneTitle>
          <Button
            kind="danger--ghost"
            disabled={!hasAllRequiredSettings}
            onClick={() => gitSettingsStore.showDeleteConnection()}
          >
            Delete provider connection
          </Button>
        </>
      </Stack>
    );
  };

  return (
    <Modal
      open={shouldShowDialog}
      size="sm"
      modalHeading="Configure repository connection"
      primaryButtonText="Save configuration"
      primaryButtonDisabled={!canSave || hasValidationErrors}
      secondaryButtonText="Cancel"
      onRequestSubmit={() => gitSettingsStore.save()}
      onSecondarySubmit={() => gitSettingsStore.close()}
      onRequestClose={() => gitSettingsStore.close()}
      loadingStatus={status}
      loadingDescription={getLoadingDescription()}
    >
      {modalContent()}
    </Modal>
  );
};

const DangerZoneTitle = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.16px;
  margin-bottom: -24px;
`;

export default observer(GitSettingsDialog);
