/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { TileGroup } from '@carbon/react';

export const TilesContainer = styled(TileGroup)`
  & > div {
    display: flex;
    gap: 8px;
    justify-content: space-around;
    flex-direction: row;
  }

  & > div > div {
    width: 50%;
  }

  & > legend {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.16px;
  }

  margin-bottom: 8px;
`;

export const TileContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const TileLabel = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;

  &,
  & > a {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
  }
`;
