/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const EmptyStateDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 15px;
`;

export const EmptyStateText = styled.div`
  b,
  span {
    display: block;
  }
`;
