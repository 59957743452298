/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Modal } from '@carbon/react';
import { observer } from 'mobx-react';
import { createPortal } from 'react-dom';

import { CloudProcess } from 'icons';

import clusterCreationModalStore from './ClusterRequiredNotificationStore';
import { Content, InnerContent, Message, Title, Visual, Wrapper } from './ClusterRequiredNotification.styled';

const ClusterRequiredNotification = () => {
  const { isVisible, close } = clusterCreationModalStore;

  const modalContent = (
    <Wrapper>
      <Modal open={isVisible} onRequestClose={() => close()} passiveModal preventCloseOnClickOutside>
        <Content>
          <Visual>
            <CloudProcess />
          </Visual>

          <InnerContent>
            <Title>Cluster required</Title>
            <Message>
              Your organization doesn't have any clusters yet. A cluster is needed to deploy, run and play your process.
            </Message>
            <Message>Contact your organization admin to create a cluster.</Message>
          </InnerContent>
        </Content>
      </Modal>
    </Wrapper>
  );

  return isVisible ? createPortal(modalContent, document.body) : null;
};

export default observer(ClusterRequiredNotification);
