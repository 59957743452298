/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

class IdpProjectService extends Service {
  constructor() {
    super();
  }

  /**
   * Creates a new IDP project in the given IDP application.
   *
   * @param {String} idpApplicationId the IDP application id
   * @param {String} name the name of the IDP project
   * @param {String} type the type of the IDP project
   * @param {String} extractionMethod the extraction method of the IDP project
   * @return {Promise}
   */
  createIdpProject({ idpApplicationId, name, type, extractionMethod }) {
    const payload = {
      idpApplicationId,
      name,
      type,
      extractionMethod
    };
    return this.post(`/internal-api/idp-projects`, payload);
  }

  /**
   * Returns the details related to the given IDP project
   *
   * @param {String} idpProjectId the IDP project id
   * @return {Promise}
   */
  fetchIdpProject(idpProjectId) {
    return this.get(`/internal-api/idp-projects/${idpProjectId}`);
  }

  /**
   * Removes an IDP project.
   *
   * @param {String} idpProjectId the IDP project id
   * @returns {Promise}
   */
  deleteIdpProject(idpProjectId) {
    return this.delete(`/internal-api/idp-projects/${idpProjectId}`);
  }

  /**
   * Deploys a process in the specified cluster
   *
   * @param {String} idpProjectId the IDP project id
   * @param {String} clusterId the id of the cluster to deploy the process to
   * @param {String} bpmnXml the xml string of the process to deploy
   * @param {String} resourceName the filename of the bpmn process. "process.bpmn" by default
   * @param {String} [tenantId] the tenant to deploy the process to. Used for Self-Managed only
   * @return {Promise}
   */
  deployProcess(idpProjectId, clusterId, bpmnXml, resourceName = 'process.bpmn', tenantId) {
    return this.post(`/internal-api/idp-projects/${idpProjectId}/deploy-process`, {
      clusterId,
      bpmnXml,
      resourceName,
      tenantId
    });
  }

  /**
   * Starts an instance of a specified process and version and returns the result
   *
   * @param {String} idpProjectId the IDP project id
   * @param {Object} payload Specification for which process to start with which variables
   * @param {String} payload.clusterId the id of the cluster to deploy the process to
   * @param {String} payload.processId the id of the cluster to deploy the process to
   * @param {Number} payload.version the id of the cluster to deploy the process to
   * @param {Object} payload.variables the id of the cluster to deploy the process to
   * @param {Number} payload.timeoutSeconds the xml string of the process to deploy
   * @param {String} [payload.tenantId] the tenant to deploy the process to. Used for Self-Managed only
   * @return {Promise}
   */
  startInstance(idpProjectId, payload) {
    return this.post(`/internal-api/idp-projects/${idpProjectId}/start-instance`, payload);
  }
}

export default new IdpProjectService();
