/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { LogoGithub, LogoGitlab } from '@carbon/icons-react';

import GitHubFields, {
  FIELDS_THAT_REQUIRE_RESYNC as GitHubFieldsThatRequireResync,
  FIELDS_VALIDATION as GitHubFieldsValidation
} from './settings/fields/GitHubFields';
import GitLabFields, {
  FIELDS_THAT_REQUIRE_RESYNC as GitLabFieldsThatRequireResync,
  FIELDS_VALIDATION as GitLabFieldsValidation
} from './settings/fields/GitLabFields';

export const SUPPORTED_GIT_PROVIDERS = {
  GITHUB: 'GITHUB',
  GITLAB: 'GITLAB'
};

export const DEFAULT_GIT_PROVIDER = SUPPORTED_GIT_PROVIDERS.GITHUB;

const COMMON_FIELDS_VALIDATION = {
  branchName: { required: true },
  path: {
    required: false,
    onBlur: (value) =>
      // replace double slashes with one, and remove leading and trailing slashes
      value.replace(/\/+/g, '/').replace(/^\/|\/$/g, '')
  }
};
export const COMMON_FIELDS_THAT_REQUIRE_RESYNC = ['branchName', 'path'];

export const SupportedGitProviders = {
  [SUPPORTED_GIT_PROVIDERS.GITHUB]: {
    label: 'GitHub',
    icon: LogoGithub,
    fields: GitHubFields,
    validationRules: { ...GitHubFieldsValidation, ...COMMON_FIELDS_VALIDATION },
    fieldsThatRequireResync: [...GitHubFieldsThatRequireResync, ...COMMON_FIELDS_THAT_REQUIRE_RESYNC],
    providerBaseUrl: 'https://github.com',
    branchPathPattern: ':repository/tree/:branch',
    documentationUrl: 'https://docs.camunda.io/docs/next/components/modeler/web-modeler/git-sync/#connect-to-github'
  },
  [SUPPORTED_GIT_PROVIDERS.GITLAB]: {
    label: 'GitLab',
    icon: LogoGitlab,
    fields: GitLabFields,
    validationRules: { ...GitLabFieldsValidation, ...COMMON_FIELDS_VALIDATION },
    fieldsThatRequireResync: [...GitLabFieldsThatRequireResync, ...COMMON_FIELDS_THAT_REQUIRE_RESYNC],
    providerBaseUrl: 'https://gitlab.com',
    branchPathPattern: ':repository/-/tree/:branch',
    documentationUrl: 'https://docs.camunda.io/docs/next/components/modeler/web-modeler/git-sync/#connect-to-gitlab'
  }
};
