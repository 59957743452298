/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import ExtractionProjectCreationModal from './extraction-project/ExtractionProject';
import DocumentAutomationProjectCreationModal from './document-automation-project/DocumentAutomationProject';

export default function CreationModal() {
  return (
    <>
      <ExtractionProjectCreationModal />
      <DocumentAutomationProjectCreationModal />
    </>
  );
}
