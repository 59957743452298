/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { TabPanel as BaseTabPanel } from '@carbon/react';

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  padding: 40px;
`;

export const Subtitle = styled.span`
  margin: 0 auto 0 0;
  font-size: 15px;
  color: rgb(51, 51, 51);
  min-width: 0;
  > span {
    flex: 1;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabsContainer = styled.main`
  margin: 20px 0 40px;
  overflow-y: auto;
`;

export const TabPanel = styled(BaseTabPanel)`
  padding: 24px 0 0;
`;

export const TabContentContainer = styled.main``;
