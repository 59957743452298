/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect } from 'react';
import { C3Page } from '@camunda/camunda-composite-components';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { Button, Column, RadioButton, Stack, Grid, TextInput, ContentSwitcher, Switch, Loading } from '@carbon/react';
import { Connect, DataConnected } from '@carbon/react/icons';

import {
  CONNECTOR_TEMPLATE,
  CONNECTOR_TEMPLATE_NAME_LENGTH,
  CONNECTOR_TEMPLATE_MAX_ICON_SIZE,
  CONNECTOR_TEMPLATE_VALID_ICON_TYPES
} from 'utils/constants';
import buildSlug from 'utils/buildSlug';
import { projectStore, breadcrumbStore, processApplicationStore } from 'stores';
import { trackingService } from 'services';
import { notificationStore } from 'components/NotificationSystem';

import Header from './Header';
import CreateNewElementTemplateFileUpload from './CreateNewElementTemplateFileUpload';
import CreateNewElementTemplateFileUploadFromUrl from './CreateNewElementTemplateFileUploadFromUrl';
import CreateNewElementTemplateDataSource from './CreateNewElementTemplateDataSource';
import CreateNewElementTemplateService from './CreateNewElementTemplateService';
import * as Styled from './CreateNewElementTemplate.styled';

export function CreateNewElementTemplate() {
  const maxIconSize = CONNECTOR_TEMPLATE_MAX_ICON_SIZE;
  const connectorCreationTypes = [
    {
      id: 'api-definition',
      title: 'Start from API definition',
      description:
        'Create a connector template based on <a href="https://swagger.io/resources/open-api/" target="_blank">OpenAPI</a> 3.0+ specification file or <a href="https://www.postman.com/collection/" target="_blank">Postman</a> 2.1.0 collection.'
    },
    {
      id: 'blank',
      title: 'Start from blank',
      description:
        'Create a connector template with preset inputs, controlling visibility and limiting selectable values.'
    }
  ];
  const iconOptions = [
    {
      id: 'default-bpmn-symbol',
      label: 'Use default BPMN symbol'
    },
    { id: 'custom-icon', label: 'Upload custom icon' }
  ];
  const dataSourceTypes = [
    {
      id: 'import-from-url',
      label: 'Import from URL'
    },
    { id: 'upload-file', label: 'Upload file' }
  ];
  const templateGeneratorTypes = [
    {
      id: 'OPEN_API',
      label: 'OpenAPI specification file (v3.0 or higher)'
    },
    { id: 'POSTMAN', label: 'Postman collection (v2.1.0)' }
  ];
  const history = useHistory();
  const { slug, processAppId } = useParams();
  const [connectorCreationType, setConnectorCreationType] = useState(connectorCreationTypes[0]?.id);
  const [iconOption, setIconOption] = useState(iconOptions[0]?.id);
  const [iconSourceType, setIconSourceType] = useState(dataSourceTypes[0]?.id);
  const [dataSourceType, setDataSourceType] = useState(dataSourceTypes[0]?.id);
  const [templateGeneratorType, setTemplateGeneratorType] = useState(templateGeneratorTypes[0]?.id);
  const [iconFileData, setIconFileData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceUrl, setDataSourceUrl] = useState();
  const [dataSourceContent, setDataSourceContent] = useState();
  const [template, setTemplate] = useState({
    name: '',
    description: '',
    icon: ''
  });

  const [checkboxes, setCheckboxes] = useState(null);

  useEffect(() => {
    projectStore.init('project', slug, processAppId);

    if (processAppId) {
      processApplicationStore.init(processAppId, true);
    }

    return () => {
      projectStore.reset();
      processApplicationStore.reset();
    };
  }, []);

  const getSelectedCheckboxes = () => {
    const checkedCheckboxIds = [];
    Object.keys(checkboxes).forEach((parentKey) => {
      const parentElement = checkboxes[parentKey];
      Object.keys(parentElement.children).forEach((childKey) => {
        const childElement = parentElement.children[childKey];
        if (childElement.selected) {
          checkedCheckboxIds.push(childElement.id);
        }
      });
    });
    return checkedCheckboxIds;
  };

  const createTemplate = async () => {
    try {
      let connectorTemplate;
      setIsLoading(true);
      if (connectorCreationType === connectorCreationTypes[0]?.id) {
        // creating element template using the template generator
        const checkedCheckboxIds = getSelectedCheckboxes();
        const generateTemplateResponse = await CreateNewElementTemplateService.generateTemplate(
          dataSourceContent,
          templateGeneratorType,
          checkedCheckboxIds
        );
        template.content = generateTemplateResponse[0];
        template.shouldOverrideProps = true;
      }
      template.icon = iconFileData?.fileContent;
      connectorTemplate = await projectStore.createFile({
        type: CONNECTOR_TEMPLATE,
        file: template
      });
      if (!connectorTemplate) {
        throw new Error('Template could not be created!');
      }
      trackingService.trackTemplateCreation({
        type: connectorCreationType,
        isIconChanged: iconFileData ? true : false
      });
      breadcrumbStore.toggleEditingFor('connector-template');
      history.push(`/connector-templates/${buildSlug(connectorTemplate.id, connectorTemplate.name)}`);
    } catch (e) {
      notificationStore.error(
        {
          title: 'Error',
          content: `Oops! We couldn't create the template.`
        },
        { shouldPersist: true }
      );
      console.error(e);
      trackingService.trackTemplateCreationError({ error: e });
    } finally {
      setIsLoading(false);
    }
  };

  const CreateElementTemplateTypeTile = (props) => {
    return (
      <Stack gap={4}>
        {props.id === 'blank' ? <Connect /> : <DataConnected />}
        <h4>{props.title}</h4>
        <p dangerouslySetInnerHTML={{ __html: props.description }} />
      </Stack>
    );
  };

  const FormElementTitle = ({ title }) => <h4>{title}</h4>;

  const TemplateTypeSelectorSection = () => {
    return (
      <Stack gap={2}>
        <Grid>
          {connectorCreationTypes.map((creationType) => (
            <Column key={creationType.id} span={8}>
              <Styled.CreateElementTemplateTypeTileWrapper>
                <Styled.CreateElementTemplateTypeRadioTile
                  key={creationType.id}
                  value={creationType.id}
                  checked={creationType.id === connectorCreationType}
                  onChange={() => setConnectorCreationType(creationType.id)}
                  disabled={false}
                >
                  <CreateElementTemplateTypeTile
                    id={creationType.id}
                    title={creationType.title}
                    description={creationType.description}
                  />
                </Styled.CreateElementTemplateTypeRadioTile>
              </Styled.CreateElementTemplateTypeTileWrapper>
            </Column>
          ))}
        </Grid>
      </Stack>
    );
  };

  const TemplateDetailsInputSection = () => {
    return (
      <Stack gap={4}>
        <Grid>
          <Column span={16}>
            <FormElementTitle title={'Configure template details'} />
          </Column>
        </Grid>
        <Grid>
          <Column span={16}>
            <TextInput
              autocomplete="off"
              data-modal-primary-focus
              type="text"
              id="template-name"
              placeholder={'Choose a descriptive name'}
              value={template.name}
              onChange={(event) => {
                setTemplate((prevTemplate) => ({
                  ...prevTemplate,
                  name: event.target.value
                }));
              }}
              labelText={'Name'}
              enableCounter
              maxCount={CONNECTOR_TEMPLATE_NAME_LENGTH}
              invalid={false}
              disabled={false}
            />
          </Column>
        </Grid>
        <Grid>
          <Column span={16}>
            <TextInput
              autocomplete="off"
              type="text"
              id="template-description"
              placeholder={'Define the template value proposition in a few words'}
              value={template.description}
              onChange={(event) => {
                setTemplate((prevTemplate) => ({
                  ...prevTemplate,
                  description: event.target.value
                }));
              }}
              labelText={'Description'}
              enableCounter
              maxCount={CONNECTOR_TEMPLATE_NAME_LENGTH}
              invalid={false}
              disabled={false}
            />
          </Column>
        </Grid>
      </Stack>
    );
  };

  const TemplateIconSection = () => {
    return (
      <Stack gap={4}>
        <Grid>
          <Column span={12}>
            <FormElementTitle title={'Icon'} />
          </Column>
        </Grid>
        <Grid>
          {iconOptions.map((option) => (
            <Styled.RadioButtonColumn key={option.id} span={16}>
              <RadioButton
                id={option.id}
                key={option.id}
                value={option.id}
                labelText={option.label}
                checked={option.id === iconOption}
                disabled={false}
                onChange={(value) => {
                  setIconOption(value);
                }}
              />
            </Styled.RadioButtonColumn>
          ))}
        </Grid>
        {iconOption === 'custom-icon' && (
          <Stack gap={5}>
            <Stack gap={2}>
              <Grid>
                <Column span={8}>
                  <ContentSwitcher
                    onChange={(data) => setIconSourceType(data.name)}
                    selectedIndex={iconSourceType === 'import-from-url' ? 0 : 1}
                  >
                    {dataSourceTypes.map((sourceType) => (
                      <Switch key={sourceType.id} name={sourceType.id} disabled={false}>
                        {sourceType.label}
                      </Switch>
                    ))}
                  </ContentSwitcher>
                </Column>
              </Grid>
            </Stack>
            <Stack gap={2}>
              {iconSourceType === 'import-from-url' && (
                <>
                  <Stack
                    gap={8}
                  >{`Icon requirements: max ${maxIconSize / 1000 - 1} KB, file types ${CONNECTOR_TEMPLATE_VALID_ICON_TYPES.join(', ')}.`}</Stack>
                  <CreateNewElementTemplateFileUploadFromUrl
                    fileData={iconFileData}
                    setFileData={setIconFileData}
                    fileSizeLimit={maxIconSize}
                  />
                </>
              )}
              {iconSourceType === 'upload-file' && (
                <Stack>
                  <Grid>
                    <Column span={16}>
                      <Stack gap={8}>
                        {`Icon requirements: max ${maxIconSize / 1000 - 1} KB, file types ${CONNECTOR_TEMPLATE_VALID_ICON_TYPES.join(', ')}.`}
                      </Stack>
                      <Stack gap={5}>
                        <CreateNewElementTemplateFileUpload
                          fileData={iconFileData}
                          setFileData={setIconFileData}
                          validFileTypes={CONNECTOR_TEMPLATE_VALID_ICON_TYPES}
                          fileSizeLimit={maxIconSize}
                        />
                      </Stack>
                    </Column>
                  </Grid>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  };

  const CreateButtonSection = () => {
    return (
      <Grid>
        <Column span={12}>
          <Button
            kind="primary"
            size="lg"
            disabled={connectorCreationType === 'api-definition' && !dataSourceContent}
            onClick={() => createTemplate()}
          >
            Create template
          </Button>
        </Column>
      </Grid>
    );
  };

  return (
    <>
      <Prompt
        when={!isLoading && (dataSourceContent?.length > 0 || iconFileData?.fileContent?.length > 0)}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {isLoading && <Loading />}
      <Helmet>
        <title>{'Create new connector template'}</title>
      </Helmet>
      <Header processAppId={processAppId} processAppName={processApplicationStore?.processApplication?.name} />
      <Styled.Wrapper className="create-new-template-wrapper">
        <C3Page
          header={{
            title: 'Create new connector template'
          }}
        >
          {
            <Styled.PageContentWrapper>
              <Stack gap={6}>
                <TemplateTypeSelectorSection />
                <Styled.Divider />
                <Stack>
                  {TemplateDetailsInputSection()}
                  <TemplateIconSection />
                </Stack>
                {connectorCreationType === 'api-definition' && (
                  <CreateNewElementTemplateDataSource
                    dataSourceTypes={dataSourceTypes}
                    dataSourceType={dataSourceType}
                    setDataSourceType={setDataSourceType}
                    templateGeneratorTypes={templateGeneratorTypes}
                    templateGeneratorType={templateGeneratorType}
                    setTemplateGeneratorType={setTemplateGeneratorType}
                    dataSourceUrl={dataSourceUrl}
                    setDataSourceUrl={setDataSourceUrl}
                    setDataSourceContent={setDataSourceContent}
                    checkboxes={checkboxes}
                    setCheckboxes={setCheckboxes}
                    FormElementTitle={FormElementTitle}
                  />
                )}
                <CreateButtonSection />
              </Stack>
            </Styled.PageContentWrapper>
          }
        </C3Page>
      </Styled.Wrapper>
    </>
  );
}

export default observer(CreateNewElementTemplate);
