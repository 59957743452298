/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { parseClusterZeebeVersion } from 'utils/cluster/cluster-version-util';

const isDocumentHandlingAvailableForCluster = (cluster) => {
  const versionInfo = parseClusterZeebeVersion(cluster.generation?.versions?.zeebe);

  if (!versionInfo) {
    // if the version information cannot be parsed (e.g. for names that do not contain numbers)
    // we assume that document handling is not allowed.
    return false;
  }

  // Document handling is only available to clusters with version > 8.7.0
  const { major, minor, patch } = versionInfo;
  return major > 8 || (major === 8 && minor > 7) || (major === 8 && minor === 7 && patch >= 0);
};

export default isDocumentHandlingAvailableForCluster;
