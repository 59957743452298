/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, TextArea, TextInput } from '@carbon/react';
import { useHistory } from 'react-router-dom';

import { trackingService } from 'services';
import { notificationStore } from 'stores';
import connectorTemplateStore from 'App/Pages/ConnectorTemplate/ConnectorTemplateStore';

import * as Styled from './DuplicateConnectorTemplateDialog.styled';
import { sanitizeTemplateName } from './utils';

const DuplicateConnectorTemplateDialog = () => {
  const history = useHistory();

  const { connectorTemplate, showDuplicateDialog, setShowDuplicateDialog, project, duplicateImportedTemplate } =
    connectorTemplateStore;
  const [defaultTemplateName, setDefaultTemplateName] = useState('');
  const [defaultTemplateDescription, setDefaultTemplateDescription] = useState('');

  let newTemplateName;
  let newTemplateDescription;

  /**
   * Set the default template name and description
   */
  useEffect(() => {
    if (connectorTemplate) {
      const content = JSON.parse(connectorTemplate.content);
      setDefaultTemplateName(`${sanitizeTemplateName(content.name)} - Copy`);
      setDefaultTemplateDescription(content.description);
    }
  }, [connectorTemplate]);

  /**
   * Tracks the opening of the duplicate dialog.
   */
  useEffect(() => {
    if (showDuplicateDialog) {
      const { id: fileId, type } = connectorTemplate;
      const { id: connectorId } = JSON.parse(connectorTemplate.content);

      trackingService.trackDuplicateImportedConnectorTemplate({
        connectorId,
        fileId,
        type
      });
    }
  }, [showDuplicateDialog]);

  const [dialogSize, setDialogSize] = useState('xs');
  const smallHeightBreakpoint = 1000;

  /**
   * Set the dialog size based on the screen height,
   * for small screens the dialog should be small and not xsmall
   * to avoid the content being cut off
   */
  useEffect(() => {
    const updateDimension = () => {
      const newDimension = getCurrentDimension();
      const shouldBeSmall = newDimension.height < smallHeightBreakpoint;
      setDialogSize(shouldBeSmall ? 'sm' : 'xs');
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);

  const closeDialog = () => {
    setShowDuplicateDialog(false);
  };

  /**
   * Saves the connector template as a new file in the project.
   */
  const duplicate = async () => {
    try {
      const fileUrl = await duplicateImportedTemplate({
        projectId: project.id,
        templateName: newTemplateName?.trim() || defaultTemplateName,
        templateDescritpion: newTemplateDescription?.trim() || defaultTemplateDescription
      });

      if (fileUrl) {
        history.push(fileUrl);
      } else {
        throw new Error('File could not be saved.');
      }
    } catch (err) {
      console.error(err);
      notificationStore.showError("Yikes! Couldn't save your connector template. Please try again later.");
    } finally {
      closeDialog();
    }
  };

  const DuplicateModal = () => (
    <Modal
      open={showDuplicateDialog}
      onRequestClose={closeDialog}
      onRequestSubmit={duplicate}
      modalHeading="Duplicate connector template"
      primaryButtonText="Duplicate"
      secondaryButtonText="Cancel"
      size={dialogSize}
    >
      <p className="bx--modal-content__text">
        Duplicate the <strong>{connectorTemplate.name || 'connector'}</strong> to easily edit and customize it to your
        preferences.
      </p>

      <Styled.InputContainer>
        <TextInput
          data-modal-primary-focus
          id="template-name"
          labelText="Template name"
          placeholder={defaultTemplateName}
          defaultValue={defaultTemplateName}
          onChange={(event) => {
            newTemplateName = event.target.value;
          }}
        />
      </Styled.InputContainer>

      <Styled.InputContainer>
        <TextArea
          id="template-description"
          labelText="Template description"
          enableCounter
          placeholder={defaultTemplateDescription}
          defaultValue={defaultTemplateDescription}
          onChange={(event) => {
            newTemplateDescription = event.target.value;
          }}
        />
      </Styled.InputContainer>
    </Modal>
  );

  return <DuplicateModal />;
};

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

export default observer(DuplicateConnectorTemplateDialog);
