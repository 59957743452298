/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link, RadioTile } from '@carbon/react';
import { observer } from 'mobx-react';

import { Tooltip } from 'primitives';
import { SupportedGitProviders } from 'features/git-sync/constants';

import gitSettingsStore from './GitSettingsStore';
import * as Styled from './ProviderSelector.styled';

const ProviderSelector = () => {
  const { configuredProvider, hasAllRequiredSettings } = gitSettingsStore;

  const onProviderChange = (provider) => {
    gitSettingsStore.changeProvider(provider);
  };

  const isDisabled = (provider) => provider !== configuredProvider && hasAllRequiredSettings;

  const renderTile = (provider, { label, icon: Icon, documentationUrl }) => {
    const output = () => (
      <RadioTile
        key={provider}
        id={provider}
        value={provider}
        data-test={`select-${provider}-provider`}
        disabled={isDisabled(provider)}
      >
        <Icon size="26" />
        <Styled.TileContent>
          <Styled.TileLabel>{label}</Styled.TileLabel>
          {documentationUrl && (
            <Link href={documentationUrl} target="_blank">
              Learn more
            </Link>
          )}
        </Styled.TileContent>
      </RadioTile>
    );

    if (isDisabled(provider)) {
      return (
        <Tooltip
          key={`${provider}-disabled-tooltip`}
          title="Delete the existing connection before changing provider"
          align="bottom"
        >
          {output()}
        </Tooltip>
      );
    } else {
      return output();
    }
  };

  return (
    <>
      <Styled.TilesContainer
        valueSelected={configuredProvider}
        legend="Choose your code repository provider"
        name="Choose your code repository provider"
        onChange={onProviderChange}
      >
        {Object.entries(SupportedGitProviders).map(([provider, { label, icon, documentationUrl }]) =>
          renderTile(provider, { label, icon, documentationUrl })
        )}
      </Styled.TilesContainer>

      <Styled.FeedbackContainer>
        Looking for another code repository provider?
        <Link href="https://forum.camunda.io" target="_blank">
          Let us know in the forum
        </Link>
      </Styled.FeedbackContainer>
    </>
  );
};

export default observer(ProviderSelector);
