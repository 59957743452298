/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { Link, TextInput } from '@carbon/react';

import { idpProjectStore } from 'stores';

import * as Styled from './../CreationModal.styled';

function DocumentAutomationProject() {
  const [name, setName] = useState('');

  const hasValidIdpProjectName = name.trim().length;

  const closeModal = () => {
    idpProjectStore.setIsDocumentAutomationProjectCreationModalVisible(false);
    setName('');
  };

  const performRequest = async () => {
    if (!hasValidIdpProjectName) {
      return;
    }

    //TODO: Handle document automation projects creation.
  };

  return (
    <Styled.IdpProjectModal
      open={idpProjectStore.isDocumentAutomationProjectCreationModalVisible}
      modalHeading="Create a document automation project"
      primaryButtonText="Create"
      //TODO: replace `primaryButtonDisabled` with `primaryButtonDisabled={!hasValidIdpProjectName}`
      // when document automation projects are supported
      primaryButtonDisabled
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={closeModal}
    >
      <Styled.IdpProjectModalContent>
        <span>
          Use LLMs to automatically sort documents into predefined types for faster and more accurate organization.
          &nbsp;
          <Link href="" target="_blank" rel="noreferrer">
            Learn more about document automation
          </Link>
          .
        </span>
        <TextInput
          autoComplete="off"
          data-modal-primary-focus
          enableCounter
          id="idp-project-name"
          labelText="Name"
          placeholder="The name should reflect the project document type. i.e. Invoice"
          value={name}
          maxCount={100}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      </Styled.IdpProjectModalContent>
    </Styled.IdpProjectModal>
  );
}

export default observer(DocumentAutomationProject);
