/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { C3ClusterTag } from '@camunda/camunda-composite-components';

import { Switch, Tooltip } from 'primitives';
import { organizationStore } from 'stores';
import getClusterGeneration from 'utils/cluster/get-cluster-generation';
import getClusterStatus from 'utils/cluster/get-cluster-status';

import ClusterStatus from './ClusterStatus';
import * as Styled from './ClustersList.styled';

export default function ClustersList({
  clusters = [],
  disabled,
  selectedClusterId,
  onClusterIdSelection,
  componentsForHealthCheck
}) {
  return (
    clusters.length > 0 && (
      <Styled.Clusters disabled={disabled}>
        {clusters?.map((cluster) => {
          const clusterStatus = getClusterStatus(cluster?.status, componentsForHealthCheck);

          return (
            <Tooltip key={cluster.uuid} disabled={!cluster.disabled} title={cluster.disabledReason} justify="left">
              <Styled.Cluster disabled={cluster.disabled}>
                <Styled.Label>
                  <Switch
                    disabled={cluster.disabled}
                    checked={selectedClusterId === cluster.uuid}
                    onChange={() => !disabled && !cluster.disabled && onClusterIdSelection(cluster.uuid)}
                  />

                  <Styled.ClusterInfo>
                    <Styled.ClusterName className="overflow-ellipsis" title={cluster.name}>
                      {cluster.name} {getClusterGeneration(cluster)}
                    </Styled.ClusterName>
                    <Styled.ClusterId>{cluster.uuid}</Styled.ClusterId>
                  </Styled.ClusterInfo>
                </Styled.Label>

                <ClusterStatus status={clusterStatus} clusterId={cluster.uuid} />

                {cluster?.labels?.camunda?.[0] && (
                  <Styled.TagContainer>
                    <C3ClusterTag stage={cluster.labels.camunda[0]} />
                  </Styled.TagContainer>
                )}

                <Styled.ClusterManageLink
                  href={`${organizationStore.consoleDashboardPageUrl}/cluster/${cluster.uuid}`}
                  target="_blank"
                >
                  Manage
                </Styled.ClusterManageLink>
              </Styled.Cluster>
            </Tooltip>
          );
        })}
      </Styled.Clusters>
    )
  );
}
