/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const validateRequired = (value) => {
  if (typeof value === 'boolean') {
    return true;
  }

  return value && value.trim() !== '' && value.length;
};

export const validatePattern = (pattern, value) => {
  if (!pattern) {
    return true;
  }

  return pattern.test(value);
};

export const isValidUrl = (resourceUrl) => {
  try {
    return Boolean(new URL(resourceUrl));
  } catch {
    return false;
  }
};
