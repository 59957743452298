/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import isDocumentHandlingAvailableForCluster from './is-document-handling-available-for-cluster';

const getClustersWithDocumentHandling = (clusters) => {
  return (
    clusters?.map((cluster) => {
      const isDocumentHandlingAvailable = isDocumentHandlingAvailableForCluster(cluster);
      return {
        ...cluster,
        disabled: !isDocumentHandlingAvailable,
        disabledReason: !isDocumentHandlingAvailable ? "This cluster doesn't support document handling." : undefined
      };
    }) ?? []
  );
};

export default getClustersWithDocumentHandling;
