/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

import { notificationStore } from 'components/NotificationSystem';
import { confirmActionStore, idpApplicationStore, userStore } from 'stores';
import buildSlug from 'utils/buildSlug';
import { tracingService, idpProjectService } from 'services';
import history from 'utils/history';
import { IDP_PROJECT_TYPE } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';

class IdpProjectStore {
  isExtractionProjectCreationModalVisible = false;
  isDocumentAutomationProjectCreationModalVisible = false;
  idpProject = null;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(loading) {
    this.loading = loading;
  }

  reset() {
    this.setLoading(true);
    this.setIdpProject({});
    idpApplicationStore.reset();
  }

  setIsExtractionProjectCreationModalVisible(isExtractionProjectCreationModalVisible) {
    this.isExtractionProjectCreationModalVisible = isExtractionProjectCreationModalVisible;
  }

  setIsDocumentAutomationProjectCreationModalVisible(isDocumentAutomationProjectCreationModalVisible) {
    this.isDocumentAutomationProjectCreationModalVisible = isDocumentAutomationProjectCreationModalVisible;
  }

  setIdpProject(idpProject) {
    this.idpProject = idpProject;
  }

  startCreation(idpProjectType) {
    switch (idpProjectType) {
      case IDP_PROJECT_TYPE.EXTRACTION:
        this.setIsExtractionProjectCreationModalVisible(true);
        this.setIsDocumentAutomationProjectCreationModalVisible(false);
        break;
      case IDP_PROJECT_TYPE.DOCUMENT_AUTOMATION:
        this.setIsExtractionProjectCreationModalVisible(false);
        this.setIsDocumentAutomationProjectCreationModalVisible(true);
        break;
    }
  }

  async init(idpProjectId, skipParentInitialization = false) {
    let idpProjectFile;
    if (userStore.isAuthenticated) {
      this.setLoading(true);
      try {
        idpProjectFile = await idpProjectService.fetchIdpProject(idpProjectId);
        this.setIdpProject(idpProjectFile);
        if (!skipParentInitialization) {
          await idpApplicationStore.init(this.idpProject.idpApplicationId);
        }
      } catch (err) {
        notificationStore.showError("Yikes! Couldn't retrieve your IDP project. Please try again later.");
        tracingService.traceError(err, 'Failed to retrieve IDP project');
      } finally {
        this.setLoading(false);
      }
    }
    return idpProjectFile;
  }

  async finalizeCreation(name, type, extractionMethod) {
    try {
      const idpApplicationId = idpApplicationStore.idpApplication.id;

      this.setIdpProject(
        await idpProjectService.createIdpProject({
          idpApplicationId,
          name,
          type,
          extractionMethod
        })
      );

      history.push(`/idp-projects/${buildSlug(this.idpProject.id, this.idpProject.name)}`);
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't create your IDP project. Please try again later.");
      tracingService.traceError(err, 'Failed to create IDP project');
    }
  }

  async delete(idpProjectId) {
    try {
      const confirmed = await confirmActionStore.confirm({
        title: `Deleting IDP project`,
        text: (
          <>
            You're about to delete the IDP project "{this.idpProject.name}" and its files. Collaborators won't be able
            to access them afterwards.
          </>
        ),
        confirmLabel: 'Delete',
        isDangerous: true
      });

      if (!confirmed) {
        return false;
      }

      await idpProjectService.deleteIdpProject(idpProjectId);

      notificationStore.showSuccess('Your IDP project has been deleted.');
      return true;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not delete your IDP project. Please try again later.');
      tracingService.traceError(ex, 'Failed to delete IDP project');

      return false;
    }
  }
}

export default new IdpProjectStore();
